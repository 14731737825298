import jsonp from "jsonp";
import { IGetCnpjDataContract } from "../../domain/contracts/getCnpjDataContract";
import {
  CnpjDataEntity,
  CnpjDataEntityType,
} from "../../domain/entities/cnpjDataEntity";
import { TypeaheadOption } from "../../domain/entities/typeaheadOption";
import { ICnpjaResponseModel } from "../models/cnpjaResponseModel";
import { IReceitaWsResponseModel } from "../models/receitaWsResponseModel";
import { IApiService } from "./apiService";
import { GetAddressByZipCodeService } from "./getAddressByZipCodeService";

export class GetCnpjDataService implements IGetCnpjDataContract {
  constructor(
    private getAddressByZipCodeService: GetAddressByZipCodeService,
    private cnpjaApi: IApiService,
  ) {}

  async getCnpjData(cnpj: string) {
    let cnpjData: CnpjDataEntityType | null = new CnpjDataEntity();

    try {
      cnpjData = await this.getCnpjDataFromReceitaWs(cnpj);
    } catch {
      cnpjData = await this.getCnpjDataFromCnpja(cnpj);
    }

    const zipCode = cnpjData?.zipCode;

    if (zipCode && cnpjData) {
      const address = await this.getAddressByZipCodeService.getAddressByZipCode(
        zipCode,
      );

      if (address) {
        cnpjData.city = address.city;
        cnpjData.state = address.state;
        cnpjData.street = address.street;
        cnpjData.neighborhood = address.neighborhood;
      }
    }

    return cnpjData;
  }

  private getCnpjDataFromReceitaWs(
    cnpj: string,
  ): Promise<CnpjDataEntityType | null> {
    const url = `https://www.receitaws.com.br/v1/cnpj/${cnpj}`;

    return new Promise<CnpjDataEntityType | null>((resolve, reject) => {
      jsonp(url, { timeout: 3000 }, (error, data: IReceitaWsResponseModel) => {
        if (error) {
          reject(error);
          return;
        }

        const cnpjData: CnpjDataEntityType = {
          name: data.fantasia,
          email: data.email,
          document: data.cnpj.replace(/\D/g, ""),
          phoneNumber: data.telefone.replace(/\D/g, ""),
          corporationName: data.nome,
          stateRegistration: "",
          municipalRegistration: "",

          street: data.logradouro?.trim(),
          zipCode: data.cep?.trim()?.replace(/\D/g, "") || "",
          placeNumber: data.numero?.trim() || "",
          neighborhood: data.bairro?.trim() || "",
          complement: data.complemento?.trim() || "",
          city: new TypeaheadOption({ label: data.municipio.trim() || "" }),
          state: new TypeaheadOption({ rawValue: data.uf.trim() || "" }),
          // nao precisamos selecionar a opcao correta aqui
          // pois só consultamos CNPJ para brasileiros
          country: new TypeaheadOption(),
        };

        resolve(cnpjData);
      });
    });
  }

  private async getCnpjDataFromCnpja(
    cnpj: string,
  ): Promise<CnpjDataEntityType> {
    const sanitizedCnpj = cnpj.replace(/\D/g, "");
    const url = `/office/${sanitizedCnpj}`;

    const response = await this.cnpjaApi.get<ICnpjaResponseModel>(url);

    const responsePhone = response.phones.at(0);

    let phoneNumber = "";

    if (responsePhone) {
      const { area, number } = responsePhone;
      phoneNumber = `${area}${number}`;
    }

    const cnpjData: CnpjDataEntityType = {
      // nome fantasia
      name: response.alias.trim(),
      email:
        response.emails
          .filter(e => e.ownership === "CORPORATE")
          .at(0)
          ?.address.trim() || "",
      document: cnpj.replace(/\D/g, ""),
      phoneNumber,
      // razao socialF
      corporationName: response.company.name.trim(),
      stateRegistration: "",
      municipalRegistration: "",

      street: response.address.street?.trim() || "",
      zipCode: response.address.zip?.replace(/\D/g, "") || "",
      placeNumber: response.address.number?.trim() || "",
      neighborhood: response.address.district?.trim() || "",
      complement: response.address.details?.trim() || "",
      city: new TypeaheadOption({ label: response.address.city?.trim() || "" }),
      state: new TypeaheadOption({
        rawValue: response.address.state?.trim() || "",
      }),
      // nao precisamos selecionar a opcao correta aqui
      // pois só consultamos CNPJ para brasileiros
      country: new TypeaheadOption(),
    };

    return cnpjData;
  }
}

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 0.5rem;

  > form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media (min-width: 1200px) {
      flex-direction: row;
    }

    input {
      &.isInvalid {
        border-color: var(--invalid);

        :focus {
          box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
        }
      }
    }

    .p-component.p-inputtext,
    .p-component.p-dropdown {
      font-size: 0.8333rem;
      padding: 0.25rem 0.5rem;
      width: 100%;
      border-radius: 0.25rem;

      .p-dropdown-label.p-inputtext.p-placeholder {
        font-size: 0.8333rem;
        line-height: 1.0333rem;
        padding-right: 0.5rem;
      }

      .p-dropdown-label.p-inputtext {
        font-size: 0.8333rem;
        line-height: 1.0333rem;
        display: inline-block;
        max-width: 150px;
      }
    }

    i.pi.pi-spin.pi-spinner {
      line-height: 1.7rem;
      margin-left: 0.4rem;
    }

    .clear-filter-button {
      line-height: normal;
      margin: unset;
      background-color: var(--soul-red-button);
      border-color: var(--soul-red-button);
      width: 100%;
      justify-content: center;
    }

    .filter-button {
      line-height: normal;
      margin: unset;
      background-color: var(--soul-color02);
      border-color: var(--soul-color02);
      width: 100%;
      justify-content: center;
    }

    .sel-macro-category-wrapper {
      display: flex;
    }
  }
`;

import ChartjsPluginDatalabels from "chartjs-plugin-datalabels";
import { Chart } from "primereact/chart";
import {
  EMPTY_CHART_DATA,
  IChartDataEntity,
} from "../../../domain/entities/chartDataEntity";
import { useChartOptions } from "../../hooks/useChartOptions";
import { ChartLoader } from "../ChartLoader";

interface ChartRevenueVersusExpensesProps {
  data: IChartDataEntity;
  loading?: boolean;
}

export function ChartRevenueVersusExpenses({
  data,
  loading = false,
}: ChartRevenueVersusExpensesProps) {
  const {
    defaultOptions,
    plugins: {
      datalabels: { bar },
    },
  } = useChartOptions();

  defaultOptions.plugins = {
    ...defaultOptions.plugins,
    datalabels: bar.top,
  };

  return (
    <ChartLoader loading={loading}>
      <div>
        <div>
          <h5 style={{ textAlign: "center" }}>Receitas x Despesas</h5>

          <Chart
            type="bar"
            data={loading ? EMPTY_CHART_DATA : data}
            options={defaultOptions}
            plugins={[ChartjsPluginDatalabels]}
          />
        </div>
      </div>
    </ChartLoader>
  );
}

import { useMemo } from "react";
import { useFormState } from "react-hook-form";
import { FaDownload } from "react-icons/fa";
import {
  ApiService,
  IApiService,
} from "../../../../../../core/data/services/apiService";
import { ViaCepApiService } from "../../../../../../core/data/services/viaCepApiService";
import { Page } from "../../../../../../core/presentation/components/Page/styles";
import { useNavLinkClick } from "../../../../../../core/presentation/hooks/useNavLinkClick";
import { makeProvider } from "../../../../../../provider/main/makeProvider";
import { ESolicitImportSteps } from "../../../domain/entities/solicitImportEnums";
import { ISolicitImportForm } from "../../../domain/entities/solicitImportTypes";
import { makeSolicitImport } from "../../../main/makeSolicitImport";
import {
  SolicitImportPageProvider,
  useSolicitImportPage,
} from "../../hooks/useSolicitImportPage";
import { FileStep } from "../FileStep";
import { FinalStep } from "../FinalStep";
import { SolicitImportPageHeader } from "../SolicitImportPageHeader";
import { ValidationStep } from "../ValidationStep";
import { Container } from "./styles";

function SolicitImportPage() {
  const {
    handleNextStep,
    handleReturnStep,
    form: { control },
    handleGetTemplate,
    handleNavLinkClick,
    state: { currentStep, isDownloadingTemplate, files },
  } = useSolicitImportPage();

  const { errors } = useFormState<ISolicitImportForm>({ control });

  /**
   * Objeto que contém os passos do fluxo de importação e seus respectivos
   * componentes.
   */
  const availableSteps = {
    [ESolicitImportSteps.FILE]: <FileStep />,
    [ESolicitImportSteps.FINAL]: <FinalStep />,
    [ESolicitImportSteps.VALIDATION]: <ValidationStep />,
  };

  const isStepValid = useMemo(() => {
    const validStates = {
      [ESolicitImportSteps.FILE]: files.length !== 0,
      [ESolicitImportSteps.FINAL]: !errors?.imports?.length,
      [ESolicitImportSteps.VALIDATION]: !errors?.imports?.length,
    };

    return validStates[currentStep];
  }, [currentStep, errors?.imports?.length, files.length]);

  useNavLinkClick(handleNavLinkClick, currentStep !== ESolicitImportSteps.FILE);

  return (
    <Container>
      <Page>
        <SolicitImportPageHeader />
        <article className="no-padding">
          {availableSteps[currentStep]}
          <div className="action-buttons">
            {currentStep === ESolicitImportSteps.FILE ? (
              <button
                type="button"
                data-testid="btn-template"
                disabled={!!isDownloadingTemplate}
                className="form-button default-button download-template"
                onClick={handleGetTemplate}
              >
                {isDownloadingTemplate ? (
                  <i className="pi pi-spin pi-spinner" />
                ) : (
                  <FaDownload />
                )}
                <span>Baixar Modelo</span>
              </button>
            ) : null}
            <button
              type="button"
              id="btn-close"
              disabled={!!isDownloadingTemplate}
              className="form-button red-bkg footer-close-button"
              onClick={handleReturnStep}
            >
              Voltar
            </button>
            <button
              type="button"
              id="btn-return"
              disabled={!!isDownloadingTemplate}
              className={`form-button ${
                isStepValid ? "green-bkg" : "invalid-bkg"
              }`}
              onClick={() => {
                if (!isStepValid) {
                  return;
                }
                handleNextStep();
              }}
            >
              {currentStep === ESolicitImportSteps.FINAL
                ? "Finalizar"
                : "Próximo"}
            </button>
          </div>
        </article>
      </Page>
    </Container>
  );
}

interface ISolicitImportPageFactoryProps {
  api: IApiService;
}

export function SolicitImportPageFactory({
  api,
}: ISolicitImportPageFactoryProps) {
  const { REACT_APP_SERVER_URL, REACT_APP_API_VERSION } = process.env;

  const baseUrl = `${REACT_APP_SERVER_URL}/api/v${REACT_APP_API_VERSION}`;
  const barcodeApi = new ApiService(baseUrl);
  const cnpjaApi = new ApiService(`${process.env.REACT_APP_CNPJA_API_URL}`);

  const viaCepApi = new ViaCepApiService();

  return (
    <SolicitImportPageProvider
      useProvider={makeProvider(api, viaCepApi, cnpjaApi)}
      useDebtImport={makeSolicitImport(api, barcodeApi)}
    >
      <SolicitImportPage />
    </SolicitImportPageProvider>
  );
}

import { ChangeEvent, useCallback, useRef, useState } from "react";
import { FaCaretDown, FaFileExcel, FaFileUpload, FaPlus } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import {
  ApiService,
  IApiError,
  IApiService,
} from "../../../../core/data/services/apiService";
import { ViaCepApiService } from "../../../../core/data/services/viaCepApiService";
import { GridOptionsContainer } from "../../../../core/presentation/components/GridOptionsContainer";
import { ImportFileModal } from "../../../../core/presentation/components/ImportFileModal";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { SoulDropdown } from "../../../../core/presentation/components/SoulDropdown";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useImportFileErrorHandlers } from "../../../../core/presentation/hooks/useImportFileErrorHandlers";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import { ExportingModalContent } from "../../../../costCenter/presentation/components/ExportingModalContent";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IErrorResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { IProviderEntity } from "../../../domain/entities/providerEntity";
import { MakeProvider, makeProvider } from "../../../main/makeProvider";
import { useProviderGrid } from "../../hooks/useProviderGrid";
import { ClassificationAccountLinkFormModal } from "../ClassificationAccountLinkFormModal";
import { ProviderFormModal } from "../ProviderFormModal";
import { Container } from "./styles";

interface ProviderPageProps {
  useProvider: MakeProvider;
}

export enum ProviderPageModalType {
  Crud,
  Import,
  ClassificationAccount,
}

export interface ProviderPageState {
  search: string;
  isLoading: boolean;
  importFiles: File[];
  globalFilter: string;
  showActivesOnly: boolean;
  pfsEvent: IPFSEventEntity;
  currentProvider?: IProviderEntity;
  currentModal: ProviderPageModalType | null;
  data?: IServerSideResponseModel<IProviderEntity[]>;
}

function ProviderPage({ useProvider }: ProviderPageProps) {
  const {
    listProviders,
    exportProviders,
    toggleProviderStatus,
    getProviderImportationTemplate,
    saveProviderImportationService,
    listLinkedClassificationsAccount,
  } = useProvider;

  const { hash } = useLocation();
  const dialog = useSoulDialog();
  const mountedRef = useIsMounted();
  const debounceTime = useDebounceTime();
  const { generatePayload } = useTables();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const importFileErrorHandler = useImportFileErrorHandlers();

  const table = useRef<ISimpleTableHandle>(null);

  const [state, setState] = useState<ProviderPageState>({
    search: "",
    importFiles: [],
    isLoading: false,
    globalFilter: "",
    showActivesOnly: false,
    pfsEvent: new PFSEventEntity(),
    currentModal: hash.includes("import") ? ProviderPageModalType.Import : null,
  });

  const handleUpdateState = useCallback(
    (newState: Partial<ProviderPageState>) => {
      setState(prevState => ({
        ...prevState,
        ...newState,
      }));
    },
    [],
  );

  const handleCloseModal = () => {
    if (state.currentModal === ProviderPageModalType.Crud) {
      table.current?.reload();
    }

    handleUpdateState({
      importFiles: [],
      currentModal: null,
      currentProvider: undefined,
    });
  };

  const handleUpdateImportFiles = (importFiles: File[]) => {
    handleUpdateState({
      importFiles,
    });
  };

  const onProviderCreated = async (providerEntity: IProviderEntity) => {
    const clsAccountList = await listLinkedClassificationsAccount(
      currentCompanyGroup?.id || "",
      providerEntity.id,
    );

    if (Array.isArray(clsAccountList.data) && clsAccountList.data.length) {
      return;
    }

    handleUpdateState({
      currentProvider: providerEntity,
      currentModal: ProviderPageModalType.ClassificationAccount,
    });
  };

  const handleToggleProvider = useCallback(
    async (providerId: string) => {
      try {
        const { active } = await toggleProviderStatus(providerId);
        const text = active ? "ativado" : "inativado";

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Fornecedor ${text} com sucesso!`,
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, toggleProviderStatus],
  );

  const { columns } = useProviderGrid({
    handleToggleProvider,
    handleOpenModal: handleUpdateState,
  });

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      handleUpdateState({
        isLoading: true,
        pfsEvent: _pfsEvent,
      });

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const response = await listProviders({
          payload,
          companyGroupId: currentCompanyGroup.id,
          showActivesOnly: state.showActivesOnly,
        });

        handleUpdateState({
          data: response,
        });
      } finally {
        handleUpdateState({
          isLoading: false,
        });
        ReactTooltip.rebuild();
      }
    },
    [
      columns,
      mountedRef,
      listProviders,
      generatePayload,
      handleUpdateState,
      state.showActivesOnly,
      currentCompanyGroup.id,
    ],
  );

  const handleShowActivesOnly = async (value: boolean) => {
    handleUpdateState({
      showActivesOnly: value,
    });

    return value;
  };

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleUpdateState({
        search: e.target.value,
      });
      debounceTime(() => {
        handleUpdateState({
          globalFilter: e.target.value,
        });
      }, 700);
    },
    [debounceTime, handleUpdateState],
  );

  const handleImportConfirmation = useCallback(async () => {
    try {
      await saveProviderImportationService(state.importFiles[0]);

      handleUpdateState({
        importFiles: [],
      });

      await dialog.fire({
        title: "Feito!",
        icon: "success",
        confirmButtonText: "OK",
        text: "Cadastros realizados com sucesso.",
      });
    } catch (error) {
      const errorResponse = (error as IApiError<IErrorResponseEntity>).response;
      importFileErrorHandler(errorResponse);
    } finally {
      dialog.close();
    }
  }, [
    dialog,
    handleUpdateState,
    state.importFiles,
    importFileErrorHandler,
    saveProviderImportationService,
  ]);

  const alertImportProcess = useCallback(() => {
    dialog.fire({
      title: "Atenção!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Ok",
      html: `<p>Se a <strong>planilha</strong> informada for <strong>válida</strong>,
      os cadastros dos Fornecedores serão <strong>realizados</strong>.</p>
      <br />
      <p>Deseja prosseguir?</p>`,
      preConfirm() {
        dialog.fire({
          title: "Aguarde...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          text: "Realizando as importações dos cadastros",
          didOpen: () => {
            dialog.showLoading();
          },
        });
        handleImportConfirmation();
      },
    });
  }, [dialog, handleImportConfirmation]);

  const handleDownloadImportationTemplate = useCallback(async () => {
    const url = await getProviderImportationTemplate();
    window.open(url);
  }, [getProviderImportationTemplate]);

  const handleExportSheet = async () => {
    dialog.fire({
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      html: <ExportingModalContent />,
    });

    try {
      await exportProviders({
        search: state.globalFilter,
        showActivesOnly: state.showActivesOnly,
      });
    } finally {
      dialog.close();
    }
  };

  return (
    <Container>
      <Page>
        <header>
          <InputSearch
            id="txt-search"
            value={state.search}
            data-testid="txt-search"
            onChange={handleSearchChange}
          />
          <GridOptionsContainer>
            <label className="actives-only">
              <Toggle
                id="btn-toggle-invalid"
                value={state.showActivesOnly}
                data-testid="btn-toggle-invalid"
                onChange={handleShowActivesOnly}
              />
              Exibir somente ativos
            </label>
            <SoulDropdown
              toggler={
                <button
                  type="button"
                  className="default-button options-context"
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              <button
                type="button"
                className="dropdown-item"
                onClick={handleExportSheet}
              >
                <FaFileExcel />
                <span title="Exportar">Exportar para excel</span>
              </button>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  handleUpdateState({
                    currentModal: ProviderPageModalType.Import,
                  });
                }}
              >
                <FaFileUpload />
                <span title="Importar">Importar Fornecedores</span>
              </button>
            </SoulDropdown>
            <button
              type="button"
              id="btn-add"
              className="default-button"
              onClick={() => {
                handleUpdateState({
                  currentModal: ProviderPageModalType.Crud,
                });
              }}
            >
              <FaPlus /> Adicionar Fornecedor
            </button>
          </GridOptionsContainer>
          <ImportFileModal
            files={state.importFiles}
            onRequestClose={handleCloseModal}
            filesHandler={alertImportProcess}
            modalTitle="Importar Fornecedores"
            setFiles={handleUpdateImportFiles}
            templateHandler={handleDownloadImportationTemplate}
            isOpen={state.currentModal === ProviderPageModalType.Import}
          />
          <ProviderFormModal
            useProvider={useProvider}
            onRequestClose={handleCloseModal}
            onProviderCreated={onProviderCreated}
            currentId={state?.currentProvider?.id || ""}
            isOpen={state.currentModal === ProviderPageModalType.Crud}
          />
          <ClassificationAccountLinkFormModal
            useProvider={useProvider}
            onRequestClose={handleCloseModal}
            providerEntity={state.currentProvider}
            isOpen={
              state.currentModal === ProviderPageModalType.ClassificationAccount
            }
          />
        </header>
        <article className="no-padding">
          <SimpleTable<IProviderEntity>
            tableRef={table}
            columns={columns}
            data={state.data}
            getList={getList}
            scrollable={false}
            loading={state.isLoading}
            globalFilter={state.globalFilter}
          />
        </article>
      </Page>
    </Container>
  );
}

interface ProviderPageFactoryProps {
  api: IApiService;
}

export function ProviderPageFactory({ api }: ProviderPageFactoryProps) {
  const viaCepApi = new ViaCepApiService();
  const cnpjaApi = new ApiService(`${process.env.REACT_APP_CNPJA_API_URL}`);

  return <ProviderPage useProvider={makeProvider(api, viaCepApi, cnpjaApi)} />;
}

import { ProviderForm } from "../../../provider/domain/entities/providerForm";
import { TypeaheadOption } from "./typeaheadOption";

export type CnpjDataEntityType = Pick<
  ProviderForm<"legalPerson">,
  | "name"
  | "email"
  | "document"
  | "phoneNumber"
  | "corporationName"
  | "stateRegistration"
  | "municipalRegistration"
  | "street"
  | "zipCode"
  | "complement"
  | "placeNumber"
  | "neighborhood"
  | "city"
  | "state"
  | "country"
>;

export class CnpjDataEntity implements CnpjDataEntityType {
  city = new TypeaheadOption();
  complement = "";
  corporationName = "";
  country = new TypeaheadOption();
  document = "";
  email = "";
  municipalRegistration = "";
  name = "";
  neighborhood = "";
  phoneNumber = "";
  placeNumber = "";
  state = new TypeaheadOption();
  stateRegistration = "";
  street = "";
  zipCode = "";

  constructor(init?: Partial<CnpjDataEntityType>) {
    Object.assign(this, init);
  }
}

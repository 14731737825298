import { StatusCodes } from "http-status-codes";
import {
  DataTableExpandedRows,
  DataTableRowToggleParams,
} from "primereact/datatable";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { UseFormGetFieldState, UseFormReturn, useForm } from "react-hook-form";
import { FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { SoulRoutes } from "../../../../../admin/domain/entities/soulRoutes";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IApiError } from "../../../../../core/data/services/apiService";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { useImportFileErrorHandlers } from "../../../../../core/presentation/hooks/useImportFileErrorHandlers";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { MakeProvider } from "../../../../../provider/main/makeProvider";
import { IErrorResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { IDebtImportEntity } from "../../domain/entities/debtImportEntity";
import { EDebtImportSteps } from "../../domain/entities/debtImportEnums";
import {
  IDebtImportForm,
  IFieldState,
} from "../../domain/entities/debtImportTypes";
import { MakeDebtImport } from "../../main/makeDebtImport";
import { ErrorsContainer } from "../components/DebtImportPage/styles";
import { EAttachmentType } from "../../../../../core/domain/entities/attachmentsGridTypes";

export type IDebtImportPageProviderProps = PropsWithChildren<{
  useProvider: MakeProvider;
  useDebtImport: MakeDebtImport;
}>;

export interface IDebtImportPageState {
  files: File[];
  showInvalidOnly: boolean;
  currentStep: EDebtImportSteps;
  isDownloadingTemplate: boolean;
  expandedRows: DataTableExpandedRows;
  paymentMethodsEnums: IEnum<string>[];
  documentStatusEnums: IEnum<string>[];
  formsValidatingDocumentNumber: number[];
}

export interface IDebtImportPageContextProps {
  handleNextStep(): void;
  handleReturnStep(): void;
  useProvider: MakeProvider;
  handleGetTemplate(): void;
  state: IDebtImportPageState;
  useDebtImport: MakeDebtImport;
  getFormIndex(id: string): number;
  form: UseFormReturn<IDebtImportForm>;
  handleFileUpdate(files: File[]): void;
  handleRowToggle(event: DataTableRowToggleParams): void;
  handleExpandAllChange(value: boolean): Promise<boolean>;
  handleInvalidOnlyChange(value: boolean): Promise<boolean>;
  showRemoveFormsDialog(isSingleForm?: boolean): Promise<boolean>;
  handleNavLinkClick(destinyRoute: string, event: Event): Promise<void>;
  handleAfterRemove(shouldReset: boolean, removeCount?: number): Promise<void>;
  handleInputClassName(
    fieldState: ReturnType<UseFormGetFieldState<IDebtImportForm>>,
  ): IFieldState;
  validateDocumentNumber(
    documentNumberValue: string,
    formIndex: number,
  ): Promise<void>;
  handleRemoveForm(
    formIds: string[],
    removeCallback: (formsIds: string[]) => boolean,
  ): Promise<void>;
  isValidatingDocument: boolean;
}

const DebtImportPageContext = createContext<IDebtImportPageContextProps>({
  getFormIndex: () => 0,
  handleNextStep: () => null,
  handleRowToggle: () => null,
  handleReturnStep: () => null,
  handleFileUpdate: () => null,
  handleGetTemplate: () => null,
  useProvider: {} as MakeProvider,
  useDebtImport: {} as MakeDebtImport,
  handleInputClassName: () => "isValid",
  handleRemoveForm: async () => undefined,
  showRemoveFormsDialog: async () => false,
  handleAfterRemove: async () => undefined,
  handleExpandAllChange: async () => false,
  handleNavLinkClick: async () => undefined,
  handleInvalidOnlyChange: async () => false,
  form: {} as UseFormReturn<IDebtImportForm>,
  validateDocumentNumber: async () => undefined,
  state: {
    files: [],
    expandedRows: {},
    showInvalidOnly: false,
    paymentMethodsEnums: [],
    documentStatusEnums: [],
    isDownloadingTemplate: false,
    formsValidatingDocumentNumber: [],
    currentStep: EDebtImportSteps.FILE,
  },
  isValidatingDocument: false,
});

export function DebtImportPageProvider(props: IDebtImportPageProviderProps) {
  const { children, useDebtImport, useProvider } = props;

  const {
    saveImportData,
    saveDebtImportFile,
    validateImportData,
    checkDocumentNumber,
    fetchDocumentStatus,
    fetchPaymentMethods,
    getDebtImportTemplate,
  } = useDebtImport;

  const [state, setState] = useState<IDebtImportPageState>({
    files: [],
    expandedRows: {},
    showInvalidOnly: false,
    paymentMethodsEnums: [],
    documentStatusEnums: [],
    isDownloadingTemplate: false,
    formsValidatingDocumentNumber: [],
    currentStep: EDebtImportSteps.FILE,
  });

  const dialog = useSoulDialog();
  const navigate = useNavigate();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const importFileErrorHandlers = useImportFileErrorHandlers();

  const form = useForm<IDebtImportForm>({
    mode: "all",
    defaultValues: {
      imports: [],
    },
  });

  const { reset, setError, getValues, resetField, clearErrors, handleSubmit } =
    form;

  const getFormIndex = useCallback(
    (id: string) => {
      const formList = getValues("imports");
      return formList.findIndex(d => d.id === id);
    },
    [getValues],
  );

  const showRemoveFormsDialog = useCallback(
    async (isSingleForm?: boolean) => {
      const result = await dialog.fire({
        icon: "warning",
        title: "Atenção",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        html: (
          <>
            Este processo <strong>removerá</strong> todos os dados{" "}
            <strong>
              {isSingleForm ? "desta linha" : "das linhas selecionadas"}
            </strong>{" "}
            do processo de importação.
            <br />
            Não é possível <strong>desfazer</strong> a remoção.
            <br />
            <br />
            Deseja continuar?
          </>
        ),
      });

      return result.isConfirmed;
    },
    [dialog],
  );

  const handleAfterRemove = useCallback(
    async (shouldReset: boolean, removeCount = 1) => {
      const text =
        removeCount > 1
          ? `${removeCount} linhas removidas com sucesso.`
          : "Linha removida com sucesso.";

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text,
      });

      if (shouldReset) {
        await dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: (
            <>
              Não há dados para serem importados.
              <br />O processo será finalizado.
            </>
          ),
        });

        setState({
          files: [],
          expandedRows: {},
          showInvalidOnly: false,
          paymentMethodsEnums: [],
          documentStatusEnums: [],
          isDownloadingTemplate: false,
          formsValidatingDocumentNumber: [],
          currentStep: EDebtImportSteps.FILE,
        });

        reset({ imports: [] });
      }
    },
    [dialog, reset],
  );

  const handleInputClassName = useCallback(
    (fieldState: ReturnType<UseFormGetFieldState<IDebtImportForm>>) => {
      if (fieldState?.error) {
        return "isInvalid";
      }

      const isDirty = fieldState.isDirty && fieldState.isTouched;

      return isDirty ? "isEdited" : "isValid";
    },
    [],
  );

  const handleRowToggle = useCallback((event: DataTableRowToggleParams) => {
    setState(old => ({
      ...old,
      expandedRows: event.data as DataTableExpandedRows,
    }));
  }, []);

  const handleExpandAllChange = useCallback(
    async (value: boolean) => {
      const formList = getValues("imports");
      const dataIdObject: Record<string, boolean> = {};

      if (value) {
        formList.map(({ id }) => {
          dataIdObject[`${id}`] = true;
          return null;
        });
      }

      setState(old => ({ ...old, expandedRows: dataIdObject }));

      return value;
    },
    [getValues],
  );

  const handleInvalidOnlyChange = useCallback(async (value: boolean) => {
    setState(old => ({
      ...old,
      showInvalidOnly: value,
    }));
    return value;
  }, []);

  const handleFileUpdate = useCallback((files: File[]) => {
    setState(old => ({
      ...old,
      files,
    }));
  }, []);

  const validateDocumentNumber = useCallback(
    async (documentNumberValue: string, formIndex: number) => {
      const accountPayableId = getValues(`imports.${formIndex}.id`);
      const provider = getValues(`imports.${formIndex}.provider`);
      const providerId = provider?.rawValue as string | undefined;

      if (!providerId || !documentNumberValue) {
        return;
      }

      const companyGroupId = currentCompanyGroup.id;

      setState(old => {
        const newList = old.formsValidatingDocumentNumber.concat(formIndex);

        return {
          ...old,
          formsValidatingDocumentNumber: newList,
        };
      });

      try {
        await checkDocumentNumber({
          companyGroupId,
          documentNumber: documentNumberValue,
          providerId,
          accountPayableId,
        });
        clearErrors(`imports.${formIndex}.documentNumber`);
      } catch {
        setError(`imports.${formIndex}.documentNumber`, {
          type: "validDocument",
        });
      } finally {
        setState(old => {
          const newList = old.formsValidatingDocumentNumber.filter(
            index => index !== formIndex,
          );

          return {
            ...old,
            formsValidatingDocumentNumber: newList,
          };
        });
      }
    },
    [
      setError,
      getValues,
      clearErrors,
      checkDocumentNumber,
      currentCompanyGroup.id,
    ],
  );

  const handleValidationErrors = useCallback(() => {
    const formList = getValues("imports");

    formList.map(({ hasError, fieldErrors }, index) => {
      if (hasError) {
        const fieldNameAndErrors = Object.entries(fieldErrors);

        fieldNameAndErrors.map(([name, errorMessage]) => {
          const fieldName = name.replace("Id", "") as keyof IDebtImportEntity;
          const fieldKey = `imports.${index}.${fieldName}` as const;

          setError(fieldKey, {
            type: "custom",
            message: errorMessage || "Este campo é obrigatório",
          });

          return null;
        });
      }

      return null;
    });
  }, [getValues, setError]);

  const handleFinalStepErrors = useCallback(() => {
    const formList = getValues("imports");

    formList.map(({ hasAssessmentError, assessments }, formIndex) => {
      if (hasAssessmentError) {
        const formPrefix = `imports.${formIndex}` as const;

        setError(`${formPrefix}.hasAssessmentError`, { type: "isFalse" });

        assessments.map(({ fieldErrors }) => {
          const hasValueLeft = fieldErrors?.valueAssessmentLeft;

          if (hasValueLeft) {
            setError(`${formPrefix}.valueAssessmentLeft`, {
              type: "custom",
            });
          }

          return null;
        });
      }

      return null;
    });
  }, [getValues, setError]);

  const handleGetTemplate = useCallback(async () => {
    setState(old => ({
      ...old,
      isDownloadingTemplate: true,
    }));

    try {
      const templateUrl = await getDebtImportTemplate();
      window.open(templateUrl);
    } finally {
      setState(old => ({
        ...old,
        isDownloadingTemplate: false,
      }));
    }
  }, [getDebtImportTemplate]);

  const handleSubmitFile = useCallback(async () => {
    dialog.fire({
      title: "Aguarde",
      text: "Carregando arquivo",
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    dialog.showLoading();

    try {
      const imports = await saveDebtImportFile(state.files[0]);

      if (!imports?.length) {
        await dialog.fire({
          icon: "warning",
          title: "Atenção",
          text: "Nenhum dado foi encontrado na planilha",
        });
        return;
      }

      reset({ imports });

      handleValidationErrors();

      const [documentStatusEnums, paymentMethodsEnums] = await Promise.all([
        fetchDocumentStatus(),
        fetchPaymentMethods(),
      ]);

      setState(old => ({
        ...old,
        files: [],
        documentStatusEnums,
        paymentMethodsEnums,
        currentStep: EDebtImportSteps.VALIDATION,
      }));
    } catch (error) {
      const errorData = error as IApiError<IErrorResponseEntity>;
      const errorResponse = errorData.response;
      if (errorData.response.status === StatusCodes.BAD_REQUEST) {
        importFileErrorHandlers(errorResponse);
      }
    } finally {
      dialog.close();
    }
  }, [
    reset,
    dialog,
    state.files,
    saveDebtImportFile,
    fetchDocumentStatus,
    fetchPaymentMethods,
    handleValidationErrors,
    importFileErrorHandlers,
  ]);

  const handleBarcodeUpdate = useCallback((imports: IDebtImportEntity[]) => {
    return imports.map(importData => {
      const importDataCopy = importData;
      if (importDataCopy.barcode) {
        const billetIndex = importDataCopy.storageFiles.findIndex(
          a => Number(a.type) === EAttachmentType.Billet && a.active,
        );
        const updatedAttachment = {
          ...importData.storageFiles[billetIndex],
          barcode: importData.barcode,
        };

        importDataCopy.storageFiles[billetIndex] = updatedAttachment;
      }
      return importDataCopy;
    });
  }, []);

  const handleFormValidation = useCallback(
    async (formsToValidate: IDebtImportForm) => {
      handleInvalidOnlyChange(false);

      dialog.fire({
        title: "Aguarde",
        text: "Validando dados da importação",
        allowEscapeKey: false,
        allowOutsideClick: false,
      });

      dialog.showLoading();

      try {
        const updatedImports = handleBarcodeUpdate(formsToValidate.imports);
        const validatedImports = await validateImportData({
          imports: updatedImports,
        });

        dialog.close();

        reset({ imports: validatedImports });

        const hasErrors = validatedImports.some(d => d.hasError);

        if (hasErrors) {
          ReactTooltip.rebuild();
          handleValidationErrors();
          return;
        }

        handleFinalStepErrors();

        setState(old => ({
          ...old,
          currentStep: EDebtImportSteps.FINAL,
        }));
      } finally {
        dialog.close();
      }
    },
    [
      reset,
      dialog,
      validateImportData,
      handleBarcodeUpdate,
      handleFinalStepErrors,
      handleValidationErrors,
      handleInvalidOnlyChange,
    ],
  );

  const checkForExistingData = useCallback(
    async (imports: IDebtImportEntity[]) => {
      const anyAccountAlreadyExists = imports.some(d => {
        return d.accountAlreadyExists;
      });
      const anyAccountAlreadyPaid = imports.some(d => {
        return d.accountAlreadyPaid;
      });

      if (!anyAccountAlreadyExists && !anyAccountAlreadyPaid) {
        return false;
      }

      const alreadyExistingItems = imports
        .filter(({ accountAlreadyExists, accountAlreadyPaid }) => {
          if (anyAccountAlreadyExists) {
            return accountAlreadyExists;
          }

          if (anyAccountAlreadyPaid) {
            return accountAlreadyPaid;
          }

          return false;
        })
        .map(({ company, provider, documentNumber }) => ({
          documentNumber,
          companyName: company?.label || "",
          providerName: provider?.label?.replace(/ - .+/g, "") || "",
        }));

      const response = await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        allowOutsideClick: false,
        html: (
          <div>
            <p>
              Os seguintes lançamentos <strong>já existem</strong> e, portanto,
              serão <strong>alterados</strong>:
            </p>
            <ErrorsContainer>
              {alreadyExistingItems.map(
                ({ documentNumber, companyName, providerName }, index) => {
                  const key = `${documentNumber}-${index}`;
                  return (
                    <div key={key}>
                      {documentNumber} - {companyName} - {providerName}
                    </div>
                  );
                },
              )}
            </ErrorsContainer>
            <p>Deseja continuar?</p>
          </div>
        ),
      });

      return response.dismiss;
    },
    [dialog],
  );

  const handleFinalStep = useCallback(
    async (importResponse: IDebtImportEntity[]) => {
      const hasErrors = importResponse.some(
        d => d.hasError || d.hasAssessmentError,
      );

      if (!hasErrors) {
        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: "Lançamentos realizados com sucesso",
        });
        navigate("/accountsPayable");
        return;
      }

      reset({ imports: importResponse });

      const hasAccountErrors = importResponse.some(d => d.hasError);

      if (hasAccountErrors) {
        await dialog.fire({
          icon: "error",
          title: "Opa!",
          text: "Não é possível continuar. Um ou mais lançamentos informados na segunda etapa se tornaram inconsistentes.",
        });

        handleValidationErrors();

        setState(old => ({
          ...old,
          currentStep: EDebtImportSteps.VALIDATION,
        }));
        return;
      }

      const hasAssessmentErrors = importResponse.some(
        d => d.hasAssessmentError,
      );

      if (hasAssessmentErrors) {
        handleFinalStepErrors();

        const mappedErrors = importResponse.flatMap(data => {
          return data.assessments.flatMap(assessment => {
            return Object.values(assessment.fieldErrors);
          });
        });

        const uniqueErrors = new Set(mappedErrors);
        const errorsList = Array.from(uniqueErrors.values());

        await dialog.fire({
          icon: "error",
          title: "Opa!",

          html: (
            <div className="import-file-error-dialog">
              <p className="import-file-error-message">
                Essa planilha <strong>não pôde</strong> ser importada. Corrija
                os problemas e tente novamente.
              </p>
              <div className="import-file-error-container">
                {errorsList.map((error, index) => {
                  const errorKey = `error#${index}`;
                  return (
                    <div key={errorKey} className="import-file-error-warning">
                      <FaTimesCircle />
                      <p>{error}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          ),
        });
      }
    },
    [dialog, handleFinalStepErrors, handleValidationErrors, navigate, reset],
  );

  const handleImportData = useCallback(
    async ({ imports }: IDebtImportForm) => {
      const cancelImportation = await checkForExistingData(imports);

      if (cancelImportation) {
        dialog.close();
        return;
      }

      dialog.fire({
        title: "Aguarde",
        text: "Validando dados da importação",
        allowEscapeKey: false,
        allowOutsideClick: false,
      });

      dialog.showLoading();

      const updatedImports = handleBarcodeUpdate(imports);

      try {
        ReactTooltip.rebuild();
        const response = await saveImportData(updatedImports);
        await handleFinalStep(response);
      } finally {
        dialog.close();
      }
    },
    [
      dialog,
      saveImportData,
      handleFinalStep,
      handleBarcodeUpdate,
      checkForExistingData,
    ],
  );

  const handleNextStep = useCallback(() => {
    const stepsActions = {
      [EDebtImportSteps.FILE]: handleSubmitFile,
      [EDebtImportSteps.FINAL]: handleSubmit(handleImportData),
      [EDebtImportSteps.VALIDATION]: handleSubmit(handleFormValidation),
    };

    stepsActions[state.currentStep]();
  }, [
    handleSubmit,
    handleSubmitFile,
    handleImportData,
    state.currentStep,
    handleFormValidation,
  ]);

  const showLeavingProcessDialog = useCallback(() => {
    return dialog.fire({
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      title: "Você está certo disso?",
      html: "Você está no meio do processo de importações.<br />Se sair agora, todo o seu progresso será perdido!",
    });
  }, [dialog]);

  const handleReturnStep = useCallback(async () => {
    const formList = getValues("imports");

    if (
      formList.length !== 0 &&
      state.currentStep === EDebtImportSteps.VALIDATION
    ) {
      const result = await showLeavingProcessDialog();
      if (result.dismiss) {
        return;
      }
    }

    const newStep = state.currentStep - 1;

    if (newStep < 0) {
      if (document?.referrer) {
        navigate("/accountsPayable");
        return;
      }
      navigate(-1);
      return;
    }

    if (newStep === EDebtImportSteps.FILE) {
      setState({
        files: [],
        expandedRows: {},
        showInvalidOnly: false,
        paymentMethodsEnums: [],
        documentStatusEnums: [],
        isDownloadingTemplate: false,
        formsValidatingDocumentNumber: [],
        currentStep: EDebtImportSteps.FILE,
      });

      reset({ imports: [] });
    }

    if (newStep === EDebtImportSteps.VALIDATION) {
      formList.map((_, index) => {
        resetField(`imports.${index}.hasAssessmentError`);
        clearErrors(`imports.${index}.hasAssessmentError`);
        resetField(`imports.${index}.valueAssessmentLeft`);
        clearErrors(`imports.${index}.valueAssessmentLeft`);
        return null;
      });

      setState(old => ({
        ...old,
        expandedRows: {},
        currentStep: newStep,
        showInvalidOnly: false,
      }));
    }
  }, [
    reset,
    navigate,
    getValues,
    resetField,
    clearErrors,
    state.currentStep,
    showLeavingProcessDialog,
  ]);

  const handleNavLinkClick = useCallback(
    async (destinyRoute: string, event: Event) => {
      const formList = getValues("imports");

      if (
        formList.length === 0 ||
        state.currentStep === EDebtImportSteps.FILE
      ) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();

      const result = await showLeavingProcessDialog();

      if (result.dismiss) {
        return;
      }

      navigate(destinyRoute || SoulRoutes.HOME.path);
    },
    [getValues, navigate, showLeavingProcessDialog, state.currentStep],
  );

  const handleRemoveForm = useCallback(
    async (
      formIds: string[],
      removeCallback: (formsIds: string[]) => boolean,
    ) => {
      const isSingleForm = formIds.length === 1;
      const shouldRemove = await showRemoveFormsDialog(isSingleForm);
      if (shouldRemove) {
        const shouldReset = removeCallback(formIds);
        handleAfterRemove(shouldReset, formIds.length);
      }
    },
    [handleAfterRemove, showRemoveFormsDialog],
  );

  const isValidatingDocument = !!state.formsValidatingDocumentNumber?.length;

  const value = useMemo(
    () => ({
      form,
      state,
      useProvider,
      getFormIndex,
      useDebtImport,
      handleNextStep,
      handleRowToggle,
      handleReturnStep,
      handleRemoveForm,
      handleFileUpdate,
      handleGetTemplate,
      handleAfterRemove,
      handleNavLinkClick,
      handleInputClassName,
      showRemoveFormsDialog,
      handleExpandAllChange,
      validateDocumentNumber,
      handleInvalidOnlyChange,
      isValidatingDocument,
    }),
    [
      form,
      state,
      useProvider,
      getFormIndex,
      useDebtImport,
      handleNextStep,
      handleRowToggle,
      handleReturnStep,
      handleFileUpdate,
      handleRemoveForm,
      handleGetTemplate,
      handleAfterRemove,
      handleNavLinkClick,
      handleInputClassName,
      handleExpandAllChange,
      showRemoveFormsDialog,
      validateDocumentNumber,
      handleInvalidOnlyChange,
      isValidatingDocument,
    ],
  );

  return (
    <DebtImportPageContext.Provider value={value}>
      {children}
    </DebtImportPageContext.Provider>
  );
}

export function useDebtImportPage() {
  const context = useContext(DebtImportPageContext);
  return context;
}

import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { FaCaretDown, FaFileExcel, FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { MakeCompany, makeCompany } from "../../../../company/main/makeCompany";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../core/data/services/apiService";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { MakeCore, makeCore } from "../../../../core/main/makeCore";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { IPaymentAccountResumedEntity } from "../../../domain/entities/paymentAccountEntity";
import {
  MakePaymentAccounts,
  makePaymentAccounts,
} from "../../../main/makePaymentAccounts";
import { usePaymentAccountsGrid } from "../../hooks/usePaymentAccountsGrid";
import { PaymentAccountFormModal } from "../PaymentAccountFormModal";
import { Container } from "./style";
import { GridOptionsContainer } from "../../../../core/presentation/components/GridOptionsContainer";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { SoulDropdown } from "../../../../core/presentation/components/SoulDropdown";
import { ExportingModalContent } from "../../../../core/presentation/components/ExportingModalContent";

interface ProjectsPageProps {
  useCore: MakeCore;
  useCompanies: MakeCompany;
  usePaymentAccounts: MakePaymentAccounts;
}

function ProjectsPage({
  useCore,
  useCompanies,
  usePaymentAccounts,
}: ProjectsPageProps) {
  const { listPaymentAccounts, togglePaymentAccount, exportPaymentAccount } =
    usePaymentAccounts;
  const { listCompanies } = useCompanies;

  const dialog = useSoulDialog();
  const mountedRef = useIsMounted();
  const debounceTime = useDebounceTime();
  const { generatePayload } = useTables();
  const allowedProfiles = useAllowedProfiles();
  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  /** Referência da tabela */
  const tableRef = useRef<ISimpleTableHandle>(null);

  const canAdd = useMemo(
    () =>
      allowedProfiles(
        EUserProfile.financialAccounting,
        EUserProfile.supervisor,
      ),
    [allowedProfiles],
  );

  /** Estados que controlam o input de filtro global */
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();

  /** Estado de carregamento da tabela */
  const [isLoading, setIsLoading] = useState(false);

  /** Armazena os dados da tabela */
  const [data, setData] =
    useState<IServerSideResponseModel<IPaymentAccountResumedEntity[]>>();

  /** Armazena o ID da conta de pagamento selecionada */
  const [currentId, setCurrentId] = useState<string>();

  const [pfsEvent, setPfsEvent] = useState<IPFSEventEntity>(
    new PFSEventEntity(),
  );
  const [showActivesOnly, setShowActivesOnly] = useState<boolean>(false);

  /**
   * Lida com a abertura do modal de edição/criação de uma conta de pagamento.
   */
  const openPaymentAccountModal = useCallback(
    (info: IPaymentAccountResumedEntity) => {
      setCurrentId(info.id);
    },
    [],
  );

  /**
   * Lida com a ação de fechar o modal ativo.
   */
  const closePaymentAccountModal = useCallback(
    (shouldRefresh: boolean | unknown) => {
      if (typeof shouldRefresh === "boolean" && shouldRefresh)
        tableRef.current?.reload();
      setCurrentId(undefined);
    },
    [],
  );

  /**
   * Alterna o status de uma conta de pagamento. (soft delete)
   */
  const toggle = useCallback(
    async (paymentAccountId: string, actionText: string) => {
      try {
        await togglePaymentAccount(paymentAccountId);

        tableRef.current?.reload();
        dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `A Conta de Pagamento foi ${actionText} com sucesso!`,
        });
      } catch (error) {
        dialog.close();
      }
    },
    [dialog, togglePaymentAccount],
  );

  const { columns } = usePaymentAccountsGrid({
    toggle,
    openPaymentAccountModal,
  });

  /**
   * Realiza a requisição para buscar os dados da tabela. Lida com os eventos
   * monitorados pela SimpleTable.
   */
  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      setPfsEvent(_pfsEvent);
      setIsLoading(true);

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const response = await listPaymentAccounts(
          payload,
          id,
          showActivesOnly,
        );

        setData(response);
      } catch (error) {
        return;
      } finally {
        setIsLoading(false);
        ReactTooltip.rebuild();
      }
    },
    [
      columns,
      generatePayload,
      id,
      listPaymentAccounts,
      mountedRef,
      showActivesOnly,
    ],
  );

  const handleShowActivesOnly = async (value: boolean) => {
    setShowActivesOnly(prevState => !prevState);

    return value;
  };

  const handleExportSheet = async () => {
    dialog.fire({
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      html: <ExportingModalContent />,
    });

    const payload = generatePayload(pfsEvent, columns as ISimpleColumn[]);

    try {
      await exportPaymentAccount({ payload, showActivesOnly });
    } finally {
      dialog.close();
    }
  };

  /**
   * Lida com as mudanças no input de filtro global da tabela.
   */
  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      debounceTime(() => {
        setGlobalFilter(e.target.value);
      }, 700);
    },
    [debounceTime],
  );

  return (
    <Container>
      <Page>
        <header>
          <InputSearch
            id="txt-search"
            value={search}
            data-testid="txt-search"
            onChange={handleSearchChange}
          />
          <GridOptionsContainer>
            <label className="actives-only">
              <Toggle
                id="btn-toggle-invalid"
                value={showActivesOnly}
                onChange={handleShowActivesOnly}
              />
              Exibir somente ativos
            </label>
            <SoulDropdown
              toggler={
                <button
                  type="button"
                  className="default-button options-context"
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              <button
                onClick={handleExportSheet}
                className="dropdown-item"
                type="button"
              >
                <FaFileExcel />
                <span title="Exportar">Exportar para excel</span>
              </button>
            </SoulDropdown>
            {canAdd ? (
              <button
                id="btn-add"
                type="button"
                className="default-button"
                onClick={() => {
                  setCurrentId("");
                }}
              >
                <FaPlus /> Adicionar Conta de Pagamento
              </button>
            ) : null}
          </GridOptionsContainer>
          <PaymentAccountFormModal
            useCore={useCore}
            currentId={currentId ?? ""}
            listCompanies={listCompanies}
            isOpen={currentId !== undefined}
            onClose={closePaymentAccountModal}
            usePaymentAccounts={usePaymentAccounts}
          />
        </header>
        <article className="no-padding">
          <SimpleTable
            data={data}
            columns={columns}
            getList={getList}
            loading={isLoading}
            tableRef={tableRef}
            globalFilter={globalFilter}
          />
        </article>
      </Page>
    </Container>
  );
}

interface PaymentAccountsPageFactoryProps {
  api: IApiService;
}

export function PaymentAccountsPageFactory({
  api,
}: PaymentAccountsPageFactoryProps) {
  return (
    <ProjectsPage
      useCore={makeCore(api)}
      useCompanies={makeCompany(api)}
      usePaymentAccounts={makePaymentAccounts(api)}
    />
  );
}

import { ChartOptions } from "chart.js";
import { Options } from "chartjs-plugin-datalabels/types/options";
import { useCurrencyFormat } from "./useCurrencyFormat";

export function useChartOptions() {
  const { format } = useCurrencyFormat();

  const datalabelsDefaultOptions: Options = {
    display: "auto",
    formatter: (strValue: string) => {
      const value = Number(strValue);
      return format({ value, showSymbol: false });
    },
  };

  const datalabelsTopOptions: Options = {
    display: "auto",
    formatter: (strValue: string) => {
      const value = Number(strValue);

      return format({
        value,
        showSymbol: false,
      });
    },
    align: "top",
    color: "black",
    anchor: "end",
    offset: -4,
  };

  const datalabels = {
    default: datalabelsDefaultOptions,
    bar: {
      top: datalabelsTopOptions,
    },
  };

  const defaultOptions: ChartOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 16,
      },
    },
    aspectRatio: 0.8,
    plugins: {
      tooltip: {
        callbacks: {
          label: context => {
            const label = context.dataset.label || "";
            const formatted = format({
              value: context.parsed.y,
              shorten: false,
            });

            return `${label}: ${formatted}`;
          },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          color: "#495057",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "transparent",
        },
      },
      y: {
        ticks: {
          display: false,
          color: "#495057",
        },
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return { defaultOptions, plugins: { datalabels } };
}

import { format, subYears } from "date-fns";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { Controller } from "react-hook-form";
import { EMPTY_OPTION } from "../../../../advTable/domain/entities/advTableColumn";
import { ISearchMacroCategoriesContract } from "../../../../core/domain/contracts/searchMacroCategoriesContract";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { IDashboardToolbarFiltersEntity } from "../../../domain/entities/dashboardToolbarFiltersEntity";
import { Container } from "./styles";
import { useDashboardToolbar } from "./useDashboardToolbar";
import { IconTooltip } from "../../../../core/presentation/components/IconTooltip";

export const DASHBOARD_DEFAULT_FILTER_VALUES = {
  endDate: format(new Date(), "dd/MM/yyyy"),
  startDate: format(subYears(new Date(), 1), "dd/MM/yyyy"),
  macroCategory: undefined,
};

interface DashboardToolbarProps {
  searchMacroCategories: ISearchMacroCategoriesContract["searchMacroCategories"];
  onFiltersChange(value: IDashboardToolbarFiltersEntity): void;
  loading?: boolean;
}

export function DashboardToolbar({
  searchMacroCategories,
  onFiltersChange,
  loading = false,
}: DashboardToolbarProps) {
  const {
    control,
    handleSubmit,
    validateDate,
    state,
    handleOnFilter,
    errorHandler,
    submitHandler,
    startDate,
    endDate,
  } = useDashboardToolbar({ searchMacroCategories, onFiltersChange });

  return (
    <Container>
      <form onSubmit={handleSubmit(submitHandler, errorHandler)}>
        <div>
          <Controller
            control={control}
            name="macroCategory"
            render={({ field }) => {
              return (
                <div className="sel-macro-category-wrapper">
                  <Dropdown
                    filter
                    showClear
                    showFilterClear
                    dataKey="rawValue"
                    optionLabel="label"
                    id="sel-macro-category"
                    onChange={field.onChange}
                    onFilter={handleOnFilter}
                    value={field.value}
                    data-testid="sel-macro-category"
                    placeholder="Macrocategoria"
                    filterPlaceholder="Macrocategoria"
                    emptyMessage="Nenhum registro encontrado."
                    emptyFilterMessage="Nenhum registro encontrado."
                    options={
                      state.macroCategoryOptions?.length
                        ? state.macroCategoryOptions
                        : EMPTY_OPTION
                    }
                    disabled={loading}
                  />
                  {state.loading && <i className="pi pi-spin pi-spinner" />}
                </div>
              );
            }}
          />
        </div>

        <div>
          <Controller
            control={control}
            name="startDate"
            rules={{
              validate: validateDate({
                fieldName: "Data inicial",
                againstValue: endDate,
                againstFieldName: "Data final",
              }),
            }}
            render={({ field, fieldState: { error } }) => {
              const isValidDate = !error?.message;

              return (
                <>
                  <InputMask
                    ref={field.ref}
                    id="txt-start-date"
                    data-testid="txt-start-date"
                    value={field.value}
                    mask="99/99/9999"
                    placeholder="Data inicial"
                    className={!isValidDate ? "isInvalid" : ""}
                    onChange={field.onChange}
                    disabled={loading}
                  />
                  <InvalidFeedback
                    condition={!isValidDate}
                    message={error?.message || ""}
                  />
                </>
              );
            }}
          />
        </div>

        <div>
          <Controller
            control={control}
            name="endDate"
            rules={{
              validate: validateDate({
                fieldName: "Data final",
                greaterThan: true,
                againstValue: startDate,
                againstFieldName: "Data inicial",
              }),
            }}
            render={({ field, fieldState: { error } }) => {
              const isValidDate = !error;

              return (
                <>
                  <InputMask
                    ref={field.ref}
                    id="txt-end-date"
                    data-testid="txt-end-date"
                    value={field.value}
                    mask="99/99/9999"
                    placeholder="Data final"
                    className={!isValidDate ? "isInvalid" : ""}
                    onChange={field.onChange}
                    disabled={loading}
                  />
                  <InvalidFeedback
                    condition={!isValidDate}
                    message={error?.message || ""}
                  />
                </>
              );
            }}
          />
        </div>

        <div className="show-btn-wrapper">
          <button
            id="btn-show"
            data-testid="btn-show"
            type="submit"
            className="default-button filter-button"
            disabled={loading}
          >
            Mostrar
          </button>
        </div>
      </form>

      <IconTooltip
        icon="pi pi-question-circle"
        text={
          <div>
            <p>
              Os cálculos da dashboard são realizados a partir do Relatório de
              Centros de Custo, considerando as seguintes regras:
            </p>

            <ul
              style={{
                color: "#525f7f",
                paddingLeft: "20px",
                marginTop: "10px",
              }}
            >
              <li>
                Os dados são calculados só uma vez por dia às 6h da manhã para
                melhor otimização;
              </li>
              <li>Somente lançamentos pagos são contabilizados;</li>
              <li>
                Os lançamentos das empresas FUSP e FEALQ são desconsiderados;
              </li>
              <li>
                Os registros de movimentação e saldo inicial são
                desconsiderados;
              </li>
              <li>Para receitas, o cliente IPECEGE é desconsiderado;</li>
              <li>Para despesas, o centro de custo ACERTO é desconsiderado.</li>
            </ul>
          </div>
        }
      />
    </Container>
  );
}

import { useMemo } from "react";
import { useFormState } from "react-hook-form";
import { FaDownload } from "react-icons/fa";
import {
  ApiService,
  IApiService,
} from "../../../../../../core/data/services/apiService";
import { ViaCepApiService } from "../../../../../../core/data/services/viaCepApiService";
import { Page } from "../../../../../../core/presentation/components/Page/styles";
import { useNavLinkClick } from "../../../../../../core/presentation/hooks/useNavLinkClick";
import { makeProvider } from "../../../../../../provider/main/makeProvider";
import { EDebtImportSteps } from "../../../domain/entities/debtImportEnums";
import { IDebtImportForm } from "../../../domain/entities/debtImportTypes";
import { makeDebtImport } from "../../../main/makeDebtImport";
import {
  DebtImportPageProvider,
  useDebtImportPage,
} from "../../hooks/useDebtImportPage";
import { DebtImportPageHeader } from "../DebtImportPageHeader";
import { FileStep } from "../FileStep";
import { FinalStep } from "../FinalStep";
import { ValidationStep } from "../ValidationStep";
import { Container } from "./styles";

function DebtImportPage() {
  const {
    handleNextStep,
    handleReturnStep,
    form: { control },
    handleGetTemplate,
    handleNavLinkClick,
    state: { currentStep, isDownloadingTemplate, files },
    isValidatingDocument,
  } = useDebtImportPage();

  const { errors } = useFormState<IDebtImportForm>({ control });

  /**
   * Objeto que contém os passos do fluxo de importação e seus respectivos
   * componentes.
   */
  const availableSteps = {
    [EDebtImportSteps.FILE]: <FileStep />,
    [EDebtImportSteps.FINAL]: <FinalStep />,
    [EDebtImportSteps.VALIDATION]: <ValidationStep />,
  };

  const isStepValid = useMemo(() => {
    const validStates = {
      [EDebtImportSteps.FILE]: files.length !== 0,
      [EDebtImportSteps.FINAL]: !errors?.imports?.length,
      [EDebtImportSteps.VALIDATION]: !errors?.imports?.length,
    };

    return validStates[currentStep];
  }, [currentStep, errors?.imports?.length, files.length]);

  useNavLinkClick(handleNavLinkClick, currentStep !== EDebtImportSteps.FILE);

  return (
    <Container>
      <Page>
        <DebtImportPageHeader />
        <article className="no-padding">
          {availableSteps[currentStep]}
          <div className="action-buttons">
            {currentStep === EDebtImportSteps.FILE ? (
              <button
                type="button"
                data-testid="btn-template"
                disabled={!!isDownloadingTemplate}
                className="form-button default-button download-template"
                onClick={handleGetTemplate}
              >
                {isDownloadingTemplate ? (
                  <i className="pi pi-spin pi-spinner" />
                ) : (
                  <FaDownload />
                )}
                <span>Baixar Modelo</span>
              </button>
            ) : null}
            <button
              type="button"
              id="btn-close"
              disabled={!!isDownloadingTemplate}
              className="form-button red-bkg footer-close-button"
              onClick={handleReturnStep}
            >
              Voltar
            </button>
            <button
              type="button"
              id="btn-next"
              disabled={!!isDownloadingTemplate || isValidatingDocument}
              className={`form-button ${
                isStepValid ? "green-bkg" : "invalid-bkg"
              }`}
              onClick={() => {
                if (!isStepValid) {
                  return;
                }
                handleNextStep();
              }}
            >
              {currentStep === EDebtImportSteps.FINAL ? "Finalizar" : "Próximo"}
            </button>
          </div>
        </article>
      </Page>
    </Container>
  );
}

interface DebtImportPageFactoryProps {
  api: IApiService;
}

export function DebtImportPageFactory({ api }: DebtImportPageFactoryProps) {
  const { REACT_APP_SERVER_URL, REACT_APP_API_VERSION } = process.env;

  const baseUrl = `${REACT_APP_SERVER_URL}/api/v${REACT_APP_API_VERSION}`;
  const barcodeApi = new ApiService(baseUrl);
  const cnpjaApi = new ApiService(`${process.env.REACT_APP_CNPJA_API_URL}`);

  const viaCepApi = new ViaCepApiService();

  return (
    <DebtImportPageProvider
      useProvider={makeProvider(api, viaCepApi, cnpjaApi)}
      useDebtImport={makeDebtImport(api, barcodeApi)}
    >
      <DebtImportPage />
    </DebtImportPageProvider>
  );
}

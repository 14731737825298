import styled, { css } from "styled-components";

interface NavItemContainerProps {
  navItemExpanded?: boolean;
  sideBarExpanded?: boolean;
}

export const NavItemContainer = styled.li<NavItemContainerProps>`
  > .nav-link {
    padding: 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    text-decoration: none;
    min-height: 44px;
    color: var(--soul-color01);

    &:is(button) {
      border: none;
      width: 100%;
      background: none;

      span.caret {
        --caret-size: 0.35rem;

        box-sizing: border-box;
        display: block;
        margin-left: auto;
        border-color: #8898aa;
        border-style: solid;
        border-width: var(--caret-size);
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        transition: transform 0.12s ease-out;
        transform: rotate(
          ${({ navItemExpanded }) => (navItemExpanded ? 90 : 0)}deg
        );
        transform-origin: left center;
      }
    }

    &:hover,
    &:active {
      color: var(--soul-color02);
    }

    &.active {
      position: relative;
      font-weight: 900;

      ::before {
        content: "";
        position: absolute;
        top: 0.25rem;
        bottom: 0.25rem;
        left: 0;
        right: auto;
        border-left: 3px solid #5e72e4;
        border-bottom: 0;
      }
    }

    > svg {
      min-width: 2.25rem;
      font-size: 0.9375rem;
      line-height: 1.5rem;

      position: relative;
      top: 2px;
      left: -10px;

      margin-right: -0.5rem !important;
    }

    > span {
      display: none;
      margin-top: 4px;
    }
  }

  ul.children {
    overflow: hidden;
    display: none;

    ${({ navItemExpanded }) => {
      if (navItemExpanded) {
        return css`
          height: auto;
        `;
      }

      return css`
        height: 0;
      `;
    }}

    li .nav-link {
      padding-left: 3.5rem;
    }
  }

  ${({ sideBarExpanded }) =>
    sideBarExpanded &&
    css`
      /*
       * FIXME em fev/2025 chrome e edge comecaram a ativar essa media query
       * incorretamente para o cursor coarse em dispositivo desktop w11
       */
      @media /* (pointer: coarse), */ (max-width: 767px) {
        .nav-link > span {
          display: block;
        }

        ul.children {
          display: block;
        }
      }
    `}
`;

export interface IDatasetEntity {
  label: string;
  data: number[];
  borderDash?: number[];
  tension?: number;
  borderColor?: string;
  backgroundColor?: string;
}

export interface IChartDataEntity {
  labels: string[];
  datasets: IDatasetEntity[];
}

export const EMPTY_CHART_DATA: IChartDataEntity = {
  datasets: [],
  labels: [],
};

import { EMPTY_CHART_DATA, IChartDataEntity } from "./chartDataEntity";

export enum EDashboardChartType {
  RevenueVersusExpenses = "revenueVersusExpenses",
  ExpenseEvolution = "expenseEvolution",
  MacroCategoryExpenses = "macroCategoryExpenses",
}

export type DashboardChartsType = Record<EDashboardChartType, IChartDataEntity>;

export interface IDashboardDataEntity {
  revenue: {
    value: number;
  };
  expenses: {
    value: number;
  };
  balance: {
    value: number;
  };
  charts: DashboardChartsType;
}

export class DashboardDataEntity implements IDashboardDataEntity {
  revenue = { value: 0 };
  expenses = { value: 0 };
  balance = { value: 0 };

  charts = {
    [EDashboardChartType.ExpenseEvolution]: EMPTY_CHART_DATA,
    [EDashboardChartType.MacroCategoryExpenses]: EMPTY_CHART_DATA,
    [EDashboardChartType.RevenueVersusExpenses]: EMPTY_CHART_DATA,
  };

  constructor(init?: Partial<IDashboardDataEntity>) {
    Object.assign(this, init);
  }
}

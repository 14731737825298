import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { SetServerSideService } from "../../core/data/services/setServerSideService";
import { ExportClassificationAssessmentService } from "../data/services/exportClassificationAssessmentService";
import { GetClassificationAssessmentService } from "../data/services/getClassificationAssessmentService";
import { ListClassificationAssessmentsService } from "../data/services/listClassificationAssessmentsService";
import { ListPaymentTypeService } from "../data/services/listPaymentTypeService";
import { SaveClassificationAssessmentService } from "../data/services/saveClassificationAssessmentService";
import { ListMacroCategoriesService } from "../data/services/listMacroCategoriesService";
import { ToggleClassificationAssessmentService } from "../data/services/toggleClassificationAssessmentService";
import { IListMacroCategoriesContract } from "../domain/contracts/listMacroCategoriesContract";
import { IExportClassificationAssessmentContract } from "../domain/contracts/exportClassificationAssessment";
import {
  GetClassificationAssessmentUseCase,
  IGetClassificationAssessmentUseCase,
} from "../domain/usecases/getClassificationAssessmentUseCase";
import {
  IListClassificationAssessmentsUseCase,
  ListClassificationAssessmentsUseCase,
} from "../domain/usecases/listClassificationAssessmentsUseCase";
import {
  IListPaymentTypeUseCase,
  ListPaymentTypeUseCase,
} from "../domain/usecases/listPaymentTypeUseCase";
import {
  ISaveClassificationAssessmentUseCase,
  SaveClassificationAssessmentUseCase,
} from "../domain/usecases/saveClassificationAssessmentUseCase";
import {
  IToggleClassificationAssessmentUseCase,
  ToggleClassificationAssessmentUseCase,
} from "../domain/usecases/toggleClassificationAssessmentUseCase";

export type MakeClassificationAssessment = IGetClassificationAssessmentUseCase &
  IListClassificationAssessmentsUseCase &
  ISaveClassificationAssessmentUseCase &
  IToggleClassificationAssessmentUseCase &
  IListPaymentTypeUseCase &
  IListMacroCategoriesContract &
  IExportClassificationAssessmentContract;

export function makeClassificationAssessment(
  api: IApiService,
): MakeClassificationAssessment {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const getClassificationAssessmentService =
    new GetClassificationAssessmentService(getUserLocalService, api);
  const getClassificationAssessmentUseCase =
    new GetClassificationAssessmentUseCase(getClassificationAssessmentService);

  const listClassificationAssessmentsService =
    new ListClassificationAssessmentsService(getUserLocalService, api);
  const listClassificationAssessmentsUseCase =
    new ListClassificationAssessmentsUseCase(
      listClassificationAssessmentsService,
    );

  const saveClassificationAssessmentService =
    new SaveClassificationAssessmentService(getUserLocalService, api);
  const saveClassificationAssessmentUseCase =
    new SaveClassificationAssessmentUseCase(
      saveClassificationAssessmentService,
    );

  const toggleClassificationAssessmentService =
    new ToggleClassificationAssessmentService(getUserLocalService, api);

  const toggleClassificationAssessmentUseCase =
    new ToggleClassificationAssessmentUseCase(
      toggleClassificationAssessmentService,
    );

  const listPaymentTypeService = new ListPaymentTypeService(
    getUserLocalService,
    api,
  );

  const listPaymentTypeUseCase = new ListPaymentTypeUseCase(
    listPaymentTypeService,
  );

  const searchMacroCategoriesService = new ListMacroCategoriesService(
    getUserLocalService,
    api,
  );

  const exportClassificationAssessmentService =
    new ExportClassificationAssessmentService(
      getUserLocalService,
      api,
      setServerSideService,
    );

  return {
    getClassificationAssessment(classificationAssessmentId) {
      return getClassificationAssessmentUseCase.getClassificationAssessment(
        classificationAssessmentId,
      );
    },
    listClassificationAssessments(companyGroupId, payload, actives) {
      return listClassificationAssessmentsUseCase.listClassificationAssessments(
        companyGroupId,
        payload,
        actives,
      );
    },
    saveClassificationAssessment(classificationAssessmentEntity) {
      return saveClassificationAssessmentUseCase.saveClassificationAssessment(
        classificationAssessmentEntity,
      );
    },
    toggleClassificationAssessment(classificationAssessmentId) {
      return toggleClassificationAssessmentUseCase.toggleClassificationAssessment(
        classificationAssessmentId,
      );
    },
    listPaymentType() {
      return listPaymentTypeUseCase.listPaymentType();
    },
    listMacroCategories() {
      return searchMacroCategoriesService.listMacroCategories();
    },
    exportClassificationAssessment(params) {
      return exportClassificationAssessmentService.exportClassificationAssessment(
        params,
      );
    },
  };
}

import { FaArrowDown, FaArrowUp, FaCoins } from "react-icons/fa";
import { IApiService } from "../../../../core/data/services/apiService";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { EDashboardChartType } from "../../../domain/entities/dashboardDataEntity";
import { makeDashboard, MakeDashboard } from "../../../main/makeDashboard";
import { ChartExpenseEvolution } from "../../components/ChartExpenseEvolution";
import { ChartMacroCategoryExpenses } from "../../components/ChartMacroCategoryExpenses";
import { ChartRevenueVersusExpenses } from "../../components/ChartRevenueVersusExpenses";
import {
  DashboardCard,
  EDashboardCardType,
} from "../../components/DashboardCard";
import { DashboardToolbar } from "../../components/DashboardToolbar";
import { Container } from "./styles";
import { useDashboardPage } from "./useDashboardPage";

interface DashboardPageProps {
  useDashboard: MakeDashboard;
}

function DashboardPage({ useDashboard }: DashboardPageProps) {
  const { searchMacroCategories } = useDashboard;

  const { balanceCardType, handleFiltersChange, state } = useDashboardPage({
    useDashboard,
  });

  return (
    <Container>
      <Page>
        <header className="custom-header">
          <DashboardToolbar
            onFiltersChange={handleFiltersChange}
            searchMacroCategories={searchMacroCategories}
            loading={state.loading}
          />
        </header>

        <article className="content">
          <div className="cards-grid">
            <div>
              <DashboardCard
                icon={<FaArrowDown className="rot-45 profit" />}
                title="Receita"
                value={state.data.revenue.value}
                type={EDashboardCardType.Profit}
                loading={state.loading}
              />
            </div>

            <div>
              <DashboardCard
                icon={<FaArrowUp className="rot-45 loss" />}
                title="Despesa"
                value={state.data.expenses.value}
                type={EDashboardCardType.Loss}
                loading={state.loading}
              />
            </div>

            <div>
              <DashboardCard
                icon={<FaCoins />}
                title="Saldo"
                value={state.data.balance.value}
                loading={state.loading}
                type={balanceCardType}
              />
            </div>
          </div>

          <div className="chart-grid">
            <div className="span-all">
              <div className="">
                <ChartRevenueVersusExpenses
                  data={
                    state.data.charts[EDashboardChartType.RevenueVersusExpenses]
                  }
                  loading={state.loading}
                />
              </div>
            </div>

            <div className="chart-wrapper">
              <ChartExpenseEvolution
                data={state.data.charts[EDashboardChartType.ExpenseEvolution]}
                loading={state.loading}
              />
            </div>

            <div className="chart-wrapper">
              <ChartMacroCategoryExpenses
                data={
                  state.data.charts[EDashboardChartType.MacroCategoryExpenses]
                }
                loading={state.loading}
              />
            </div>
          </div>
        </article>

        <div className="spacer" />
      </Page>
    </Container>
  );
}

interface DashboardPageFactoryProps {
  api: IApiService;
}

export function DashboardPageFactory({ api }: DashboardPageFactoryProps) {
  const useDashboard = makeDashboard({ api });

  return <DashboardPage useDashboard={useDashboard} />;
}

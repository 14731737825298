import { EDashboardChartType } from "../../domain/entities/dashboardDataEntity";

export enum EChartDataId {
  LineMonth = "line_month",
  BarMonth = "bar_month",
  BarMacro = "bar_macro",
}

export const CHART_DATA_ID_MAP: Record<EChartDataId, EDashboardChartType> = {
  [EChartDataId.LineMonth]: EDashboardChartType.ExpenseEvolution,
  [EChartDataId.BarMacro]: EDashboardChartType.MacroCategoryExpenses,
  [EChartDataId.BarMonth]: EDashboardChartType.RevenueVersusExpenses,
};

export interface IDataset {
  label: string | null;
  data: number[];
}

export interface IChartsData {
  id: EChartDataId;
  labels: (string | null)[];
  datasets: IDataset[];
}

export interface IDashboardDataModel {
  expenses: number;
  revenues: number;
  balance: number;
  chartsData: IChartsData[];
}

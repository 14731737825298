import { differenceInDays, isAfter, isBefore, isValid, parse } from "date-fns";

export const RANGE_GREATER_THAN_ONE_YEAR_ERROR_MESSAGE =
  "O intervalo de tempo não pode ser maior que 1 ano";

interface IValidateDateParams {
  fieldName: string;
  greaterThan?: boolean;
  againstValue: string;
  againstFieldName: string;
}

export function useValidateDate() {
  return {
    validateDate({
      fieldName,
      againstValue,
      greaterThan = false,
      againstFieldName,
    }: IValidateDateParams) {
      return (value: string) => {
        const sanitizedValue = (value || "").replace(/\D/g, "");

        if (sanitizedValue === "") {
          return `${fieldName} é obrigatória`;
        }

        const parsedValue = parse(sanitizedValue, "ddMMyyyy", new Date());

        if (!isValid(parsedValue)) {
          return `${fieldName} é inválida`;
        }

        const sanitizedAgainstValue = (againstValue || "").replace(/\D/g, "");

        const parsedAgainst = parse(
          sanitizedAgainstValue,
          "ddMMyyyy",
          new Date(),
        );

        const testFn = greaterThan ? isBefore : isAfter;

        if (testFn(parsedValue, parsedAgainst)) {
          const verb = greaterThan ? "maior" : "menor";

          return `${fieldName} deve ser ${verb} que ${againstFieldName.toLocaleLowerCase()}`;
        }

        let difference = differenceInDays(parsedValue, parsedAgainst);
        difference = Math.abs(difference);

        if (difference > 366) {
          return RANGE_GREATER_THAN_ONE_YEAR_ERROR_MESSAGE;
        }

        return true;
      };
    },
  };
}

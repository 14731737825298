import { ReactNode } from "react";
import styled from "styled-components";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";

const Container = styled.div`
  position: relative;

  .loader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--text-muted-light);
    z-index: 10;
    justify-content: center;
    align-items: center;
    display: none;

    &[data-loading="true"] {
      display: flex;
    }
  }
`;

interface ChartLoaderProps {
  children?: ReactNode;
  loading?: boolean;
}

export function ChartLoader({ loading, children }: ChartLoaderProps) {
  return (
    <Container>
      <div data-loading={loading} className="loader-overlay">
        <SoulSpinner />
      </div>
      {children}
    </Container>
  );
}

interface IFormatParams {
  value: number;
  shorten?: boolean;
  showSymbol?: boolean;
  maximumFractionDigits?: number;
}

export function useCurrencyFormat() {
  return {
    format({
      value,
      shorten = true,
      showSymbol = true,
      maximumFractionDigits = undefined,
    }: IFormatParams) {
      const digitsTable: Record<number, string> = {
        12: "tri",
        9: "bi",
        6: "mi",
        3: "mil",
      };

      if (shorten) {
        for (let i = 4; i > 0; i -= 1) {
          const digitsCount = i * 3;
          const limit = 1 * 10 ** digitsCount;

          const abbreviation = digitsTable[digitsCount];

          if (Math.abs(value) > limit) {
            const result = value / limit;

            const formatter = Intl.NumberFormat("pt-BR", {
              roundingMode: "trunc",
              maximumFractionDigits:
                typeof maximumFractionDigits === "undefined"
                  ? 0
                  : maximumFractionDigits,
              minimumFractionDigits:
                typeof maximumFractionDigits === "undefined"
                  ? 0
                  : maximumFractionDigits,
              // NOTE o cast abaixo foi necessario pois a opcao roundingMode
              // nao esta disponivel para a nossa versao TypeScript durante
              // compile time apesar do lint reconhecer a tipagem como valida
            } as unknown as Intl.NumberFormatOptions);

            let formatted = formatter.format(result);
            formatted = `${formatted} ${abbreviation}`;

            if (showSymbol) {
              formatted = `R$ ${formatted}`;
            }

            return formatted;
          }
        }
      }

      const formatter = Intl.NumberFormat("pt-BR", {
        roundingMode: "trunc",
        maximumFractionDigits,
        minimumFractionDigits:
          typeof maximumFractionDigits === "undefined"
            ? 2
            : maximumFractionDigits,
        // NOTE o cast abaixo foi necessario pois a opcao roundingMode
        // nao esta disponivel para a nossa versao TypeScript durante
        // compile time apesar do lint reconhecer a tipagem como valida
      } as unknown as Intl.NumberFormatOptions);

      let formatted = formatter.format(value);

      if (showSymbol) {
        formatted = `R$ ${formatted}`;
      }

      return formatted;
    },
  };
}

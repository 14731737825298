import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import { ReactNode } from "react";
import styled from "styled-components";
import { useCurrencyFormat } from "../../hooks/useCurrencyFormat";

const Container = styled.div`
  padding: 0.5rem 1rem;
  border: 1px solid rgb(222 226 230 / 60%);
  border-radius: var(--border-radius);
  box-shadow: 0 0.1875rem 0.5rem 0 rgb(136 152 170 / 40%);
  color: #495057;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  header {
    .title {
      display: inline-flex;
      align-items: center;
      gap: 0.5em;
    }
  }

  section {
    color: black;
    font-weight: bold;
    font-size: 1.25rem;

    &[data-type="none"] {
      color: black;
    }

    &[data-type="profit"] {
      color: var(--soul-green-button);
    }

    &[data-type="loss"] {
      color: var(--color-danger);
    }
  }
`;

export enum EDashboardCardType {
  None = "none",
  Profit = "profit",
  Loss = "loss",
}

interface DashboardCardProps {
  icon?: ReactNode;
  title: string;
  value: number;
  footer?: string;
  type?: EDashboardCardType;
  loading?: boolean;
}

export function DashboardCard({
  icon = undefined,
  title,
  value,
  footer = "",
  type = EDashboardCardType.None,
  loading = false,
}: DashboardCardProps) {
  const { format } = useCurrencyFormat();

  const renderHeader = () => {
    if (loading) {
      return (
        <header>
          <Skeleton height="1.5rem" />
        </header>
      );
    }

    return (
      <header>
        <h4 className="title">
          {icon}
          {title}
        </h4>
      </header>
    );
  };

  const renderBody = () => {
    if (loading) {
      return (
        <section>
          <Skeleton height="1.875rem" />
        </section>
      );
    }

    const className = `body-${(Math.random() * 10)
      .toString(16)
      .replace(/\D/, "")
      .slice(0, 4)}`;

    return (
      <>
        <section data-type={type}>
          <span className={className}>
            {format({ value, maximumFractionDigits: 2, shorten: false })}
          </span>
        </section>

        <Tooltip target={`.${className}`} position="top">
          {format({ value, shorten: false })}
        </Tooltip>
      </>
    );
  };

  const renderFooter = () => {
    if (!footer) {
      return null;
    }

    if (loading) {
      return (
        <footer>
          <Skeleton />
        </footer>
      );
    }

    return (
      <footer>
        <small>{footer}</small>
      </footer>
    );
  };

  return (
    <Container>
      {renderHeader()}
      {renderBody()}
      {renderFooter()}
    </Container>
  );
}

import { Location, matchPath } from "react-router-dom";
import { SoulRoutes } from "../../domain/entities/soulRoutes";

/**
 * Altera e define título do documento de acordo com a rota atual.
 * @param location - Objeto que contém as informações a respeito da rota atual.
 * @returns Uma string contendo o título da rota atual.
 */
export function usePageTitle(location: Location) {
  const matchedRoute = Object.values(SoulRoutes).find(data =>
    matchPath({ path: data.path, end: false }, location.pathname),
  );

  const title = matchedRoute?.title || SoulRoutes.HOME.title;

  if (typeof document !== undefined) {
    document.title = `${title} · Soul`;
  }

  return title;
}

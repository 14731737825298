import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: inherit;

  .rot-45 {
    transform: rotateZ(45deg);
  }

  .profit {
    color: var(--soul-green-button);
  }

  .loss {
    color: var(--color-danger);
  }

  .custom-header {
    gap: 0.5rem;
    display: flex;
    padding-left: 1rem;
    flex-direction: column;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 1.5rem;
  }

  .cards-grid {
    display: grid;
    grid-template-columns: 1fr;
    columns: 1;
    gap: 1rem;

    @media (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr;
      columns: 3;
    }
  }

  .chart-grid {
    display: grid;
    grid-template-columns: 1fr;
    columns: 1;
    gap: 2rem;
    position: relative;

    .chart-wrapper {
      max-width: calc(100vw - 20px);

      &.center {
        margin: auto;
      }
    }

    @media (min-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      columns: 2;

      .span-all {
        grid-column: 1 / span 2;

        position: relative;
      }

      .chart-wrapper {
        max-width: 40vw;
      }
    }
  }

  .spacer {
    height: 12vh;
  }
`;

import { useCallback, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import {
  ApiService,
  IApiService,
} from "../../../../core/data/services/apiService";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { useApiResponseErrorHandlers } from "../../../../core/presentation/hooks/useApiResponseErrorHandlers";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { makeUser, MakeUser } from "../../../main/makeUser";
import { useUserGrid } from "../../hooks/useUserGrid";
import { UserFormModal } from "../UserFormModal";
import { Container } from "./styles";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { IAuthUserEntity } from "../../../../core/domain/entities/authUserEntity";
import { IServerSideResponseModel } from "../../../../core/data/models/serverSideResponseModel";
import { Toggle } from "../../../../core/presentation/components/Toggle";

interface UserPageProps {
  useUser: MakeUser;
}

function UserPage({ useUser }: UserPageProps) {
  const table = useRef<ISimpleTableHandle>(null);
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState();
  const [isUserModalOpen, setIsUserModalOpen] = useState<boolean>(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [showActivesOnly, setShowActivesOnly] = useState(false);

  const debounceTime = useDebounceTime();
  const {
    user: { profile: currentProfile },
  } = useUserLocal();

  const isSupervisor = currentProfile === EUserProfile.supervisor;

  const {
    currentCompanyGroup: { id: currentCompanyGroupId },
  } = useCurrentCompanyGroup();

  /** Armazena o valor do grupo de empresa selecionado. */
  const oldCompanyGroupId = useRef(currentCompanyGroupId);

  const handleSearchOnChange = useCallback(
    ({ target: { value } }) => {
      setSearch(value);

      debounceTime(() => {
        setGlobalFilter(value);
      }, 700);
    },
    [debounceTime],
  );

  const openUserFormModal = useCallback((currentId = "") => {
    setCurrentUserId(currentId);
    setIsUserModalOpen(true);
  }, []);

  const { listCompanyGroups } = useCurrentCompanyGroup();

  const closeUserFormModal = useCallback(() => {
    setIsUserModalOpen(false);
    setCurrentUserId("");
    listCompanyGroups();
    table.current?.reload();
  }, [listCompanyGroups]);

  const { listAuthUsers, resetUserPassword, toggleUser } = useUser;

  const dialog = useSoulDialog();

  const resetPassword = useCallback(
    async (userId: string) => {
      try {
        const result = await dialog.fire({
          icon: "warning",
          title: "Você está certo disso?",
          html: (
            <>
              A senha do usuário será alterada para <b>soul123</b>.
              <br /> Deseja prosseguir?
            </>
          ),
          showCancelButton: true,
          cancelButtonText: "Não",
          async preConfirm() {
            dialog.update({
              allowEscapeKey: false,
              allowOutsideClick: false,
            });

            dialog.showLoading();

            return resetUserPassword(userId);
          },
        });

        if (result.dismiss) {
          return;
        }

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: "Senha resetada com sucesso.",
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, resetUserPassword],
  );

  const toggle = useCallback(
    async currentId => {
      try {
        const { active } = await toggleUser(currentId);
        const text = active ? "ativado" : "inativado";
        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Usuário ${text} com sucesso!`,
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, toggleUser],
  );

  const { columns } = useUserGrid({
    openEditModal: openUserFormModal,
    resetPassword,
    toggle,
  });
  const { generatePayload } = useTables();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<
    IServerSideResponseModel<IAuthUserEntity[]> | undefined
  >();

  const mountedRef = useIsMounted();

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      if (oldCompanyGroupId.current !== currentCompanyGroupId) {
        oldCompanyGroupId.current = currentCompanyGroupId;
      }

      setLoading(true);

      const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
      const userList = await listAuthUsers({
        payload,
        isSupervisor,
        actives: showActivesOnly,
      });

      if (mountedRef.current) {
        setData(userList);
        setLoading(false);
      }

      // Isso é necessário pois temos elementos dinamicos
      // com tooltip e o ReactTooltip precisa escanea-los
      ReactTooltip.rebuild();
    },
    [
      columns,
      mountedRef,
      isSupervisor,
      listAuthUsers,
      generatePayload,
      currentCompanyGroupId,
      showActivesOnly,
    ],
  );

  const handleToggleShowActivesOnly = useCallback(async (value: boolean) => {
    setShowActivesOnly(prev => !prev);
    return value;
  }, []);

  return (
    <Container>
      <Page>
        <header className="header">
          <InputSearch
            id="txt-search"
            data-testid="txt-search"
            value={search}
            onChange={handleSearchOnChange}
          />
          <div className="wrapper">
            <label>
              <Toggle
                value={showActivesOnly}
                onChange={handleToggleShowActivesOnly}
              />
              Exibir somente ativos
            </label>
            <button
              type="button"
              className="default-button"
              id="btn-add"
              onClick={() => openUserFormModal()}
            >
              <FaPlus /> Adicionar Usuário
            </button>
          </div>
          <UserFormModal
            isOpen={isUserModalOpen}
            onRequestClose={closeUserFormModal}
            currentId={currentUserId}
            useUser={useUser}
            isSupervisor={isSupervisor}
          />
        </header>

        <article className="no-padding">
          <SimpleTable
            tableRef={table}
            data={data}
            loading={loading}
            columns={columns}
            globalFilter={globalFilter}
            getList={getList}
          />
        </article>
      </Page>
    </Container>
  );
}

interface UserPageFactoryProps {
  api: IApiService;
}

export function UserPageFactory({ api }: UserPageFactoryProps) {
  const { REACT_APP_PECEGE_AUTH_API_URL, REACT_APP_API_VERSION } = process.env;
  const baseUrl = `${REACT_APP_PECEGE_AUTH_API_URL}/api/v${REACT_APP_API_VERSION}`;

  const apiResponseErrorHandlers = useApiResponseErrorHandlers();

  /**
   * REVIEW talvez possamos fazer essa inicialização
   * em outro lugar, por ora deixaremos aqui
   */
  const authApi = new ApiService(baseUrl, apiResponseErrorHandlers);

  return <UserPage useUser={makeUser(api, authApi)} />;
}

import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { ChangeEvent, FocusEvent, useMemo, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InputMask } from "primereact/inputmask";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { ProviderForm } from "../../../domain/entities/providerForm";
import { MakeProvider } from "../../../main/makeProvider";
import { ProviderFormModalState } from "../ProviderFormModal";
import { OptionalLabel } from "./styles";
import { ProviderType } from "../../../domain/entities/providerEntity";

interface BankFormProps {
  useProvider: MakeProvider;
  bankDataRequired: boolean;
  banks: ProviderFormModalState["enums"]["banks"];
  bankAccountTypes: ProviderFormModalState["enums"]["bankAccountTypes"];
}

export function BankForm(props: BankFormProps) {
  const { bankDataRequired, bankAccountTypes, banks, useProvider } = props;
  const { checkCpf } = useProvider;
  const refPrevCpf = useRef("");

  const bankOptions = useMemo(() => {
    return banks.map(bankEnum => {
      const bankCode = bankEnum.key.toString().padStart(3, "0");
      const value = `${bankCode} - ${bankEnum.value}`;

      return {
        value,
        key: bankEnum.key,
      };
    });
  }, [banks]);

  const {
    watch,
    register,
    resetField,
    getFieldState,
    formState: { errors },
  } = useFormContext<ProviderForm>();

  const type = watch("type");
  const documentHolder = Boolean(watch("documentHolder"));
  const isBankAccountSelected = Boolean(watch("bankAccountType"));

  const isRequired = bankDataRequired || isBankAccountSelected;
  const showDocumentHolder = type === ProviderType.legalPerson;

  const validateCpf = async (cpf: string) => {
    const { error } = getFieldState("documentHolder");
    const defaultDocLength = 11;

    if (cpf?.length === 0) {
      return true;
    }

    if (cpf?.length < defaultDocLength) {
      return "invalidDocumentCharacter";
    }

    if (cpf?.length === defaultDocLength && refPrevCpf.current !== cpf) {
      refPrevCpf.current = cpf;
      const { Code } = await checkCpf(cpf);

      if (Code === 100) {
        return "invalidDocument";
      }
      return true;
    }

    if (error) return error.message;

    return true;
  };

  const onChangeBankAccountType = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!event.target.value) {
      resetField("bank", { defaultValue: "" });
      resetField("bankBranch", { defaultValue: "" });
      resetField("bankAccount", { defaultValue: "" });
      resetField("documentHolder", { defaultValue: "" });
      resetField("bankBranchDigit", { defaultValue: "" });
      resetField("bankAccountDigit", { defaultValue: "" });
      resetField("holderName", { defaultValue: "" });
    }
  };

  const handleBlurDocumentHolder = (event: FocusEvent<HTMLInputElement>) => {
    const document = event.target.value;

    if (!document.length) {
      resetField("holderName", { defaultValue: "" });
    }
  };

  if (typeof type !== "number") {
    return null;
  }

  return (
    <div className="form-section">
      <p>Dados Bancários</p>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>
            Tipo de conta bancária{" "}
            {bankDataRequired ? null : <small>(opcional)</small>}
          </span>
          <select
            className={errors.bankAccountType ? "isInvalid" : ""}
            {...register("bankAccountType", {
              required: bankDataRequired,
              onChange: onChangeBankAccountType,
            })}
          >
            <option value="">Tipo de conta bancária</option>
            {bankAccountTypes.map(bankAccountEnum => {
              return (
                <option key={bankAccountEnum.key} value={bankAccountEnum.key}>
                  {bankAccountEnum.value}
                </option>
              );
            })}
          </select>
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors?.bankAccountType?.type === "required"}
          />
        </label>
      </div>
      <div className="form-row">
        {showDocumentHolder ? (
          <label className="col-6 form-control">
            <span>Nome do titular</span>
            <input
              type="text"
              className={errors.holderName ? "isInvalid" : ""}
              placeholder="Nome do titular"
              disabled={!documentHolder || !isBankAccountSelected}
              {...register("holderName", {
                required: documentHolder,
                shouldUnregister: true,
              })}
            />
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={errors?.holderName?.type === "required"}
            />
          </label>
        ) : null}
        {showDocumentHolder ? (
          <label className="col-6 form-control">
            <span>
              Documento do Titular <small>(opcional)</small>
            </span>
            <Controller
              name="documentHolder"
              shouldUnregister
              rules={{
                maxLength: 11,
                validate: v => validateCpf(v),
              }}
              render={({ field, fieldState }) => (
                <>
                  <InputMask
                    unmask
                    autoClear={false}
                    onBlur={handleBlurDocumentHolder}
                    placeholder="Documento do titular"
                    value={field.value}
                    mask="999.999.999-99"
                    onChange={field.onChange}
                    className={fieldState?.error ? "isInvalid" : ""}
                    disabled={!isBankAccountSelected}
                  />
                  <InvalidFeedback
                    condition={
                      fieldState?.error?.message === "invalidDocumentCharacter"
                    }
                    message="CPF inválido"
                  />
                  <InvalidFeedback
                    condition={fieldState?.error?.message === "invalidDocument"}
                    message="CPF inválido"
                  />
                </>
              )}
            />
          </label>
        ) : null}
      </div>
      <div className="form-row">
        <label className="col-6 form-control">
          <span>Banco {isRequired ? null : <small>(opcional)</small>}</span>
          <Controller
            name="bank"
            rules={{ required: isRequired }}
            render={({ field, fieldState }) => {
              const onChange = (event: DropdownChangeParams) => {
                field.onChange(event.value);
              };

              return (
                <>
                  <Dropdown
                    filter
                    showClear
                    {...field}
                    filterBy="value"
                    optionValue="key"
                    optionLabel="value"
                    onChange={onChange}
                    options={bankOptions}
                    placeholder="Selecione um banco"
                    disabled={!isBankAccountSelected}
                    className={fieldState.error ? "p-invalid" : ""}
                  />
                  <InvalidFeedback
                    message="Este campo é obrigatório"
                    condition={fieldState?.error?.type === "required"}
                  />
                </>
              );
            }}
          />
        </label>
        <label className="col-2 form-control">
          <span>Agência</span>
          <input
            placeholder="Agência"
            disabled={!isBankAccountSelected}
            className={errors.bankBranch ? "isInvalid" : ""}
            {...register("bankBranch", { required: isRequired })}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors?.bankBranch?.type === "required"}
          />
        </label>
        <label className="col-1 form-control">
          <span>DV</span>
          <input
            maxLength={1}
            placeholder="DV"
            disabled={!isBankAccountSelected}
            {...register("bankBranchDigit")}
          />
          <OptionalLabel>(opcional)</OptionalLabel>
        </label>
        <label className="col-2 form-control">
          <span>Conta</span>
          <input
            placeholder="Conta"
            disabled={!isBankAccountSelected}
            className={errors.bankAccount ? "isInvalid" : ""}
            {...register("bankAccount", { required: isRequired })}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors.bankAccount?.type === "required"}
          />
        </label>
        <label className="col-1 form-control">
          <span>DV</span>
          <input
            maxLength={1}
            placeholder="DV"
            disabled={!isBankAccountSelected}
            className={errors?.bankAccountDigit ? "isInvalid" : ""}
            {...register("bankAccountDigit", { required: isRequired })}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors.bankAccountDigit?.type === "required"}
          />
        </label>
      </div>
    </div>
  );
}

import { SoulRoutes } from "../../../admin/domain/entities/soulRoutes";
import { useCurrentCompanyGroup } from "../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { useIsRouteAvailable } from "../../../admin/presentation/hooks/useIsRouteAvailable";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { useUserLocal } from "../../../core/presentation/hooks/useUserLocal";
import { LinkCardProps } from "../components/LinkCard";

export function useHomePageLinks(): LinkCardProps[] {
  const { user } = useUserLocal();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const isRouteAvailable = useIsRouteAvailable();

  const currentProfile = user.profile;
  const companyGroupName = currentCompanyGroup.name;

  const homeLinks = Object.values(SoulRoutes)
    .filter(route => {
      const currentRoute = route.path;

      return isRouteAvailable.forHomePage({
        companyGroupName,
        currentProfile,
        currentRoute,
      });
    })
    .sort((aa, b) => {
      return (aa.home?.order || 0) < (b.home?.order || 0) ? -1 : 1;
    })
    .map(route => {
      const isPaymentRequest = route.path.includes("paymentRequest");
      const isRequesterOrManager = [
        EUserProfile.manager,
        EUserProfile.requester,
      ].includes(user.profile);

      const shouldHideDropdown = isPaymentRequest && !isRequesterOrManager;

      return {
        icon: route.icon,
        path: route.path,
        title: route.title,
        color: route.home?.color || "",
        dropdown: shouldHideDropdown ? undefined : route.home?.dropdown,
      };
    });

  return homeLinks;
}

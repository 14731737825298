import { matchPath } from "react-router-dom";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { companyGroupConfig } from "../../domain/entities/companyGroupConfig";
import {
  SoulPages,
  SoulRoute,
  SoulRoutes,
} from "../../domain/entities/soulRoutes";

export interface IUseIsRouteAvailableParams {
  companyGroupName: string;
  currentRoute: string;
  currentProfile: EUserProfile;
}

export function useIsRouteAvailable() {
  const findRoute = (currentRoute: string) => {
    return Object.entries(SoulRoutes).find(([, data]) =>
      matchPath({ path: data.path, end: false }, currentRoute),
    ) as [SoulPages, SoulRoute];
  };

  const testRouteAllowed = (
    routeConfig: [SoulPages, SoulRoute],
    allowedPages: SoulPages[],
  ): false | SoulRoute => {
    const [routeName, routeObject] = routeConfig;

    if (allowedPages && !allowedPages.includes(routeName)) {
      return false;
    }

    return routeObject;
  };

  return {
    forRouter({
      currentProfile,
      currentRoute,
      companyGroupName,
    }: IUseIsRouteAvailableParams) {
      const routeFound = findRoute(currentRoute);
      const allowedPages = companyGroupConfig?.[companyGroupName]?.routes;

      const result = testRouteAllowed(routeFound, allowedPages);

      if (result) {
        const routeObject = result;
        return routeObject.profile.includes(currentProfile);
      }

      return false;
    },

    forHomePage({
      companyGroupName,
      currentProfile,
      currentRoute,
    }: IUseIsRouteAvailableParams) {
      const routeFound = findRoute(currentRoute);
      const allowedPages = companyGroupConfig?.[companyGroupName]?.routes;

      const result = testRouteAllowed(routeFound, allowedPages);

      if (result) {
        const routeObject = result;

        return (
          !!routeObject?.home && routeObject.profile.includes(currentProfile)
        );
      }

      return false;
    },
  };
}

import { useCurrentCompanyGroup } from "./useCurrentCompanyGroup";
import {
  IUseIsRouteAvailableParams,
  useIsRouteAvailable,
} from "./useIsRouteAvailable";

type UseRouterGuardParams = Omit<
  IUseIsRouteAvailableParams,
  "companyGroupName"
>;

export function useRouterGuard(props: UseRouterGuardParams) {
  const { currentRoute, currentProfile } = props;

  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const isRouteAvailable = useIsRouteAvailable();

  const companyGroupName = currentCompanyGroup.name;

  return isRouteAvailable.forRouter({
    companyGroupName,
    currentProfile,
    currentRoute,
  });
}

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IUserEntity } from "../../../../core/domain/entities/userEntity";
import { useGoHome } from "../../../../core/presentation/hooks/useGoHome";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";
import { AuthInputDataset } from "../../../data/models/authInputDataset";
import { makeAuth, MakeAuth } from "../../../main/makeAuth";

interface AuthProps {
  useAuth: MakeAuth;
}

export function Auth({ useAuth }: AuthProps) {
  const { encrypt, decrypt, clearLocalStorage } = useAuth;

  const params = useParams();

  const { shouldGoToDashboard, goToDashboard, goToHome } = useGoHome();
  const { setUser } = useUserLocal();

  const { cyphertext } = params;

  useEffect(() => {
    if (!cyphertext) {
      setUser({
        userId: "",
        name: "",
        email: "",
        active: false,
        profile: 0,
        token: "",
      });

      const partnerId = process.env.REACT_APP_PARTNER_ID ?? "";
      const urlRedirect = process.env.REACT_APP_REDIRECT_AUTH_URL ?? "";

      const auth: AuthInputDataset = {
        partnerId,
        urlRedirect,
      };

      clearLocalStorage();

      const encryptedAuthDataset = encrypt(auth);
      const url = `${process.env.REACT_APP_PECEGE_AUTH_URL}/admin/${encryptedAuthDataset}`;
      document.location.href = url;
      return;
    }

    const userData = JSON.parse(decrypt(cyphertext ?? "")) as IUserEntity;

    if (userData) {
      setUser(userData);

      if (shouldGoToDashboard(userData.profile)) {
        goToDashboard();
      } else {
        goToHome();
      }
    }
  }, [
    clearLocalStorage,
    cyphertext,
    decrypt,
    encrypt,
    goToDashboard,
    goToHome,
    setUser,
    shouldGoToDashboard,
  ]);

  return <div />;
}

export function AuthFactory() {
  return <Auth useAuth={makeAuth()} />;
}

import { Chart } from "primereact/chart";
import {
  EMPTY_CHART_DATA,
  IChartDataEntity,
} from "../../../domain/entities/chartDataEntity";
import { useChartOptions } from "../../hooks/useChartOptions";
import { ChartLoader } from "../ChartLoader";

interface ChartExpenseEvolutionProps {
  data: IChartDataEntity;
  loading?: boolean;
}

export function ChartExpenseEvolution({
  data,
  loading = false,
}: ChartExpenseEvolutionProps) {
  const { defaultOptions } = useChartOptions();

  return (
    <ChartLoader loading={loading}>
      <div>
        <div>
          <h5 style={{ textAlign: "center" }}>
            Evolução despesas por Macrocategorias
          </h5>

          <Chart
            type="line"
            data={loading ? EMPTY_CHART_DATA : data}
            options={defaultOptions}
          />
        </div>
      </div>
    </ChartLoader>
  );
}

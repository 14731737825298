import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { SearchMacroCategoriesService } from "../../core/data/services/searchMacroCategoriesService";
import { SetServerSideService } from "../../core/data/services/setServerSideService";
import { ISearchMacroCategoriesContract } from "../../core/domain/contracts/searchMacroCategoriesContract";
import { GetDashboardDataService } from "../data/services/getDashboardDataService";
import { IGetDashboardDataContract } from "../domain/contracts/getDashboardDataContract";

export type MakeDashboard = ISearchMacroCategoriesContract &
  IGetDashboardDataContract;

interface IMakeDashboardParams {
  api: IApiService;
}

export function makeDashboard({ api }: IMakeDashboardParams): MakeDashboard {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const srchMacroCategoriesSvc = new SearchMacroCategoriesService(
    getUserLocalService,
    api,
    setServerSideService,
  );

  const getDashboardDataSvc = new GetDashboardDataService(
    getUserLocalService,
    api,
  );

  return {
    searchMacroCategories(params) {
      return srchMacroCategoriesSvc.searchMacroCategories(params);
    },
    getDashboardData(params) {
      return getDashboardDataSvc.getDashboardData(params);
    },
  };
}

import { Navigate, useNavigate } from "react-router-dom";
import { useUserLocal } from "./useUserLocal";
import { EUserProfile } from "../../domain/entities/userEntity";
import { SoulRoutes } from "../../../admin/domain/entities/soulRoutes";

export function useGoHome() {
  const navigate = useNavigate();

  const {
    user: { profile },
  } = useUserLocal();

  const goToDashboard = () => {
    const { path } = SoulRoutes.DASHBOARD;
    navigate(path);
  };

  const goToHome = () => {
    const { path } = SoulRoutes.HOME;
    navigate(path);
  };

  const shouldGoToDashboard = (userProfile: EUserProfile) => {
    return SoulRoutes.DASHBOARD.profile.includes(userProfile);
  };

  return {
    goToDashboard,
    goToHome,
    shouldGoToDashboard,
    goHome() {
      if (shouldGoToDashboard(profile)) {
        goToDashboard();
      }

      goToHome();
    },
    asComponent() {
      return function GoHome() {
        if (shouldGoToDashboard(profile)) {
          return <Navigate to={SoulRoutes.DASHBOARD.path} />;
        }

        return <Navigate to={SoulRoutes.HOME.path} />;
      };
    },
  };
}

import { useEffect } from "react";
import Modal from "react-modal";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { AdminFactory } from "./admin/presentation/components/AdminPage";
import { MasterGaurdedRoute } from "./admin/presentation/components/MasterGaurdedRoute";
import { makeAdvTable } from "./advTable/main/makeAdvTable";
import { AuthFactory } from "./auth/presentation/components/AuthPage";
import { makeUserLocal } from "./core/main/makeUserLocal";
import { ForbiddenPage } from "./core/presentation/components/ForbiddenPage";
import { NotFoundPage } from "./core/presentation/components/NotFoundPage";
import { TablesProvider } from "./core/presentation/hooks/useTables";
import { UserLocalProvider } from "./core/presentation/hooks/useUserLocal";
import { GlobalStyle } from "./core/presentation/styles/global";
import { makeSimpleTable } from "./simpleTable/main/makeSimpleTable";

Modal.setAppElement("#root");

export function App() {
  // Ação necessária para evitar que o react conflite seu life
  // cicle com as dependencias externas da camada de regócio.
  const userLocal = makeUserLocal();
  const simpleTable = makeSimpleTable();
  const advTable = makeAdvTable();

  // Isso é necessário pois temos elementos dinamicos
  // com tooltip e o ReactTooltip precisa escanea-los
  ReactTooltip.rebuild();

  useEffect(() => {
    const htmlElement = document.querySelector<HTMLHtmlElement>("html");

    if (htmlElement?.classList.contains("dev-env")) {
      htmlElement?.classList.remove("dev-env");
    }

    // se nao for o dominio de producao
    if (!window.location.host.includes("soul.pecege.com"))
      htmlElement?.classList.add("dev-env");
  }, []);

  return (
    <>
      <UserLocalProvider userLocal={userLocal}>
        <TablesProvider simpleTable={simpleTable} advTable={advTable}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<AuthFactory />} />
              <Route path="auth/:cyphertext" element={<AuthFactory />} />
              <Route
                path="*"
                element={
                  <MasterGaurdedRoute>
                    <AdminFactory />
                  </MasterGaurdedRoute>
                }
              />
              <Route path="notFound" element={<NotFoundPage />} />
              <Route path="forbidden" element={<ForbiddenPage />} />
            </Routes>
          </BrowserRouter>
        </TablesProvider>
      </UserLocalProvider>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        theme="colored"
      />

      <GlobalStyle />
      <ReactTooltip className="app-tooltip" html />
    </>
  );
}

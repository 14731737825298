import { StatusCodes } from "http-status-codes";
import {
  DataTableExpandedRows,
  DataTableRowToggleParams,
} from "primereact/datatable";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UseFormGetFieldState, UseFormReturn, useForm } from "react-hook-form";
import { FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { SoulRoutes } from "../../../../../admin/domain/entities/soulRoutes";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IApiError } from "../../../../../core/data/services/apiService";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { EUserProfile } from "../../../../../core/domain/entities/userEntity";
import { useImportFileErrorHandlers } from "../../../../../core/presentation/hooks/useImportFileErrorHandlers";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import { useUserLocal } from "../../../../../core/presentation/hooks/useUserLocal";
import { MakeProvider } from "../../../../../provider/main/makeProvider";
import { IErrorResponseEntity } from "../../../../../simpleTable/domain/entities/responseEntity";
import { ISolicitImportEntity } from "../../domain/entities/solicitImportEntity";
import {
  ESolicitImportDestination,
  ESolicitImportStatus,
  ESolicitImportSteps,
  solicitImportDestinationLang,
} from "../../domain/entities/solicitImportEnums";
import {
  IFieldState,
  ISolicitImportForm,
} from "../../domain/entities/solicitImportTypes";
import { MakeSolicitImport } from "../../main/makeSolicitImport";
import {
  DestinationContainer,
  ErrorsContainer,
  StatusContainer,
} from "../components/SolicitImportPage/styles";
import { EAttachmentType } from "../../../../../core/domain/entities/attachmentsGridTypes";

export type ISolicitImportPageProviderProps = PropsWithChildren<{
  useProvider: MakeProvider;
  useDebtImport: MakeSolicitImport;
}>;

export interface ISolicitImportPageState {
  files: File[];
  showInvalidOnly: boolean;
  isUploadingFile: boolean;
  currentStep: ESolicitImportSteps;
  isDownloadingTemplate: boolean;
  expandedRows: DataTableExpandedRows;
  paymentMethodsEnums: IEnum<string>[];
  documentStatusEnums: IEnum<string>[];
  formsValidatingDocumentNumber: number[];
  availableDestinations: ESolicitImportDestination[];
}

export interface ISolicitImportPageContextProps {
  handleNextStep(): void;
  handleReturnStep(): void;
  useProvider: MakeProvider;
  handleGetTemplate(): void;
  state: ISolicitImportPageState;
  useDebtImport: MakeSolicitImport;
  getFormIndex(id: string): number;
  form: UseFormReturn<ISolicitImportForm>;
  handleFileUpdate(files: File[]): void;
  handleRowToggle(event: DataTableRowToggleParams): void;
  handleExpandAllChange(value: boolean): Promise<boolean>;
  handleInvalidOnlyChange(value: boolean): Promise<boolean>;
  showRemoveFormsDialog(isSingleForm?: boolean): Promise<boolean>;
  handleNavLinkClick(destinyRoute: string, event: Event): Promise<void>;
  handleAfterRemove(shouldReset: boolean, removeCount?: number): Promise<void>;
  handleInputClassName(
    fieldState: ReturnType<UseFormGetFieldState<ISolicitImportForm>>,
  ): IFieldState;
  validateDocumentNumber(
    documentNumberValue: string,
    formIndex: number,
  ): Promise<void>;
  handleRemoveForm(
    formIds: string[],
    removeCallback: (formsIds: string[]) => boolean,
  ): Promise<void>;
}

const SolicitImportPageContext = createContext<ISolicitImportPageContextProps>({
  getFormIndex: () => 0,
  handleNextStep: () => null,
  handleRowToggle: () => null,
  handleReturnStep: () => null,
  handleFileUpdate: () => null,
  handleGetTemplate: () => null,
  useProvider: {} as MakeProvider,
  useDebtImport: {} as MakeSolicitImport,
  handleInputClassName: () => "isValid",
  handleRemoveForm: async () => undefined,
  showRemoveFormsDialog: async () => false,
  handleAfterRemove: async () => undefined,
  handleExpandAllChange: async () => false,
  handleNavLinkClick: async () => undefined,
  handleInvalidOnlyChange: async () => false,
  form: {} as UseFormReturn<ISolicitImportForm>,
  validateDocumentNumber: async () => undefined,
  state: {
    files: [],
    expandedRows: {},
    showInvalidOnly: false,
    isUploadingFile: false,
    paymentMethodsEnums: [],
    documentStatusEnums: [],
    availableDestinations: [],
    isDownloadingTemplate: false,
    formsValidatingDocumentNumber: [],
    currentStep: ESolicitImportSteps.FILE,
  },
});

export function SolicitImportPageProvider(
  props: ISolicitImportPageProviderProps,
) {
  const { children, useDebtImport, useProvider } = props;

  const {
    saveImportData,
    fetchDestinations,
    validateImportData,
    checkDocumentNumber,
    fetchDocumentStatus,
    fetchPaymentMethods,
    saveSolicitImportFile,
    getSolicitImportTemplate,
  } = useDebtImport;

  const [state, setState] = useState<ISolicitImportPageState>({
    files: [],
    expandedRows: {},
    showInvalidOnly: false,
    isUploadingFile: false,
    paymentMethodsEnums: [],
    documentStatusEnums: [],
    availableDestinations: [],
    isDownloadingTemplate: false,
    formsValidatingDocumentNumber: [],
    currentStep: ESolicitImportSteps.FILE,
  });

  const dialog = useSoulDialog();
  const navigate = useNavigate();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const importFileErrorHandlers = useImportFileErrorHandlers();
  const {
    user: { profile },
  } = useUserLocal();

  const isRequesterOrManager = [
    EUserProfile.manager,
    EUserProfile.requester,
  ].includes(profile);

  const form = useForm<ISolicitImportForm>({
    mode: "all",
    defaultValues: {
      imports: [],
    },
  });

  const { reset, setError, getValues, resetField, clearErrors, handleSubmit } =
    form;

  const getFormIndex = useCallback(
    (id: string) => {
      const formList = getValues("imports");
      return formList.findIndex(d => d.id === id);
    },
    [getValues],
  );

  const showRemoveFormsDialog = useCallback(
    async (isSingleForm?: boolean) => {
      const result = await dialog.fire({
        icon: "warning",
        title: "Atenção",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        html: (
          <>
            Este processo <strong>removerá</strong> todos os dados{" "}
            <strong>
              {isSingleForm ? "desta linha" : "das linhas selecionadas"}
            </strong>{" "}
            do processo de importação.
            <br />
            Não é possível <strong>desfazer</strong> a remoção.
            <br />
            <br />
            Deseja continuar?
          </>
        ),
      });

      return result.isConfirmed;
    },
    [dialog],
  );

  const handleAfterRemove = useCallback(
    async (shouldReset: boolean, removeCount = 1) => {
      const text =
        removeCount > 1
          ? `${removeCount} linhas removidas com sucesso.`
          : "Linha removida com sucesso.";

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text,
      });

      if (shouldReset) {
        await dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: (
            <>
              Não há dados para serem importados.
              <br />O processo será finalizado.
            </>
          ),
        });

        setState({
          files: [],
          expandedRows: {},
          availableDestinations: [],
          showInvalidOnly: false,
          isUploadingFile: false,
          paymentMethodsEnums: [],
          documentStatusEnums: [],
          isDownloadingTemplate: false,
          formsValidatingDocumentNumber: [],
          currentStep: ESolicitImportSteps.FILE,
        });

        reset({ imports: [] });
      }
    },
    [dialog, reset],
  );

  const handleInputClassName = useCallback(
    (fieldState: ReturnType<UseFormGetFieldState<ISolicitImportForm>>) => {
      if (fieldState?.error) {
        return "isInvalid";
      }

      const isDirty = fieldState.isDirty && fieldState.isTouched;

      return isDirty ? "isEdited" : "isValid";
    },
    [],
  );

  const handleRowToggle = useCallback((event: DataTableRowToggleParams) => {
    setState(old => ({
      ...old,
      expandedRows: event.data as DataTableExpandedRows,
    }));
  }, []);

  const handleExpandAllChange = useCallback(
    async (value: boolean) => {
      const formList = getValues("imports");
      const dataIdObject: Record<string, boolean> = {};

      if (value) {
        formList.map(({ id }) => {
          dataIdObject[`${id}`] = true;
          return null;
        });
      }

      setState(old => ({ ...old, expandedRows: dataIdObject }));

      return value;
    },
    [getValues],
  );

  const handleInvalidOnlyChange = useCallback(async (value: boolean) => {
    setState(old => ({
      ...old,
      showInvalidOnly: value,
    }));
    return value;
  }, []);

  const handleFileUpdate = useCallback((files: File[]) => {
    setState(old => ({
      ...old,
      files,
    }));
  }, []);

  const validateDocumentNumber = useCallback(
    async (documentNumberValue: string, formIndex: number) => {
      const paymentRequestId = getValues(`imports.${formIndex}.id`);
      const provider = getValues(`imports.${formIndex}.provider`);
      const providerId = provider?.rawValue as string | undefined;

      if (!providerId || !documentNumberValue) {
        return;
      }

      const companyGroupId = currentCompanyGroup.id;

      setState(old => {
        const newList = old.formsValidatingDocumentNumber.concat(formIndex);

        return {
          ...old,
          formsValidatingDocumentNumber: newList,
        };
      });

      try {
        await checkDocumentNumber({
          companyGroupId,
          documentNumber: documentNumberValue,
          providerId,
          paymentRequestId,
        });
        clearErrors(`imports.${formIndex}.documentNumber`);
      } catch {
        setError(`imports.${formIndex}.documentNumber`, {
          type: "validDocument",
        });
      } finally {
        setState(old => {
          const newList = old.formsValidatingDocumentNumber.filter(
            index => index !== formIndex,
          );

          return {
            ...old,
            formsValidatingDocumentNumber: newList,
          };
        });
      }
    },
    [
      setError,
      getValues,
      clearErrors,
      checkDocumentNumber,
      currentCompanyGroup.id,
    ],
  );

  const handleValidationErrors = useCallback(() => {
    const formList = getValues("imports");

    formList.map(({ hasError, fieldErrors }, index) => {
      if (hasError) {
        const fieldNameAndErrors = Object.entries(fieldErrors);

        fieldNameAndErrors.map(([name, errorMessage]) => {
          const fieldName = name.replace(
            "Id",
            "",
          ) as keyof ISolicitImportEntity;
          const fieldKey = `imports.${index}.${fieldName}` as const;
          setError(fieldKey, {
            type: "custom",
            message: errorMessage || "Este campo é obrigatório",
          });
          return null;
        });
      }

      return null;
    });
  }, [getValues, setError]);

  const handleFinalStepErrors = useCallback(() => {
    const formList = getValues("imports");

    formList.map(({ hasAssessmentError, assessments }, formIndex) => {
      if (hasAssessmentError) {
        const formPrefix = `imports.${formIndex}` as const;

        setError(`${formPrefix}.hasAssessmentError`, { type: "isFalse" });

        assessments.map(({ fieldErrors }) => {
          const hasValueLeft = fieldErrors?.valueAssessmentLeft;

          if (hasValueLeft) {
            setError(`${formPrefix}.valueAssessmentLeft`, {
              type: "custom",
            });
          }

          return null;
        });
      }

      return null;
    });
  }, [getValues, setError]);

  const handleGetTemplate = useCallback(async () => {
    setState(old => ({
      ...old,
      isDownloadingTemplate: true,
    }));

    try {
      const templateUrl = await getSolicitImportTemplate();
      window.open(templateUrl);
    } finally {
      setState(old => ({
        ...old,
        isDownloadingTemplate: false,
      }));
    }
  }, [getSolicitImportTemplate]);

  const handleSubmitFile = useCallback(async () => {
    setState(old => ({
      ...old,
      isUploadingFile: true,
    }));

    dialog.fire({
      title: "Aguarde",
      text: "Carregando arquivo",
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    dialog.showLoading();

    try {
      const imports = await saveSolicitImportFile(state.files[0]);

      if (!imports?.length) {
        await dialog.fire({
          icon: "warning",
          title: "Atenção",
          text: "Nenhum dado foi encontrado na planilha",
        });
        return;
      }

      reset({ imports });

      handleValidationErrors();

      const [documentStatusEnums, paymentMethodsEnums, availableDestinations] =
        await Promise.all([
          fetchDocumentStatus(),
          fetchPaymentMethods(),
          fetchDestinations(),
        ]);

      dialog.close();

      setState(old => ({
        ...old,
        files: [],
        documentStatusEnums,
        paymentMethodsEnums,
        availableDestinations,
        currentStep: ESolicitImportSteps.VALIDATION,
      }));
    } catch (error) {
      setState(old => ({
        ...old,
        isUploadingFile: false,
      }));
      const errorData = error as IApiError<IErrorResponseEntity>;
      const errorResponse = errorData.response;
      if (errorData.response.status === StatusCodes.BAD_REQUEST) {
        importFileErrorHandlers(errorResponse);
      }
    }
  }, [
    reset,
    dialog,
    state.files,
    fetchDestinations,
    fetchDocumentStatus,
    fetchPaymentMethods,
    saveSolicitImportFile,
    handleValidationErrors,
    importFileErrorHandlers,
  ]);

  const handleBarcodeUpdate = useCallback((imports: ISolicitImportEntity[]) => {
    return imports.map(importData => {
      const importDataCopy = importData;
      if (importDataCopy.barcode) {
        const billetIndex = importDataCopy.storageFiles.findIndex(
          a => Number(a.type) === EAttachmentType.Billet && a.active,
        );
        const updatedAttachment = {
          ...importData.storageFiles[billetIndex],
          barcode: importData.barcode,
        };

        importDataCopy.storageFiles[billetIndex] = updatedAttachment;
      }
      return importDataCopy;
    });
  }, []);

  const handleFormValidation = useCallback(
    async (formsToValidate: ISolicitImportForm) => {
      handleInvalidOnlyChange(false);

      dialog.fire({
        title: "Aguarde",
        text: "Validando dados da importação",
        allowEscapeKey: false,
        allowOutsideClick: false,
      });

      dialog.showLoading();

      try {
        const updatedImports = handleBarcodeUpdate(formsToValidate.imports);
        const validatedImports = await validateImportData({
          imports: updatedImports,
        });

        dialog.close();

        reset({ imports: validatedImports });

        const hasErrors = validatedImports.some(d => d.hasError);

        if (hasErrors) {
          ReactTooltip.rebuild();
          handleValidationErrors();
          return;
        }

        handleFinalStepErrors();

        setState(old => ({
          ...old,
          currentStep: ESolicitImportSteps.FINAL,
        }));
      } finally {
        dialog.close();
      }
    },
    [
      reset,
      dialog,
      validateImportData,
      handleBarcodeUpdate,
      handleFinalStepErrors,
      handleValidationErrors,
      handleInvalidOnlyChange,
    ],
  );

  const checkForAttachments = useCallback(
    async (imports: ISolicitImportEntity[]) => {
      const hasAnyRowWithAttachments = imports.some(formRow => {
        return formRow.storageFiles.length > 0;
      });

      if (hasAnyRowWithAttachments) {
        return false;
      }

      const itemsWithNoAttachments = imports
        .filter(({ storageFiles }) => {
          return storageFiles.length === 0;
        })
        .map(({ company, provider, documentNumber }) => ({
          documentNumber,
          companyName: company?.label || "",
          providerName: provider?.label?.replace(/ - .+/g, "") || "",
        }));

      const response = await dialog.fire({
        icon: "warning",
        title: "Atenção!",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        allowOutsideClick: false,
        html: (
          <>
            <p>Um ou mais lançamentos não possuem anexos vinculados.</p>
            <ErrorsContainer>
              {itemsWithNoAttachments.map(
                ({ documentNumber, companyName, providerName }, index) => {
                  const key = `${documentNumber}-${index}`;
                  return (
                    <div key={key}>
                      {documentNumber} - {companyName} - {providerName}
                    </div>
                  );
                },
              )}
            </ErrorsContainer>
            <p>Deseja realmente continuar?</p>
          </>
        ),
      });

      return response.dismiss;
    },
    [dialog],
  );

  const defineRequestsDestinations = useCallback(async () => {
    if (state.availableDestinations.length === 1) {
      return state.availableDestinations[0];
    }

    const result = await dialog.fire({
      icon: "question",
      allowEnterKey: false,
      showConfirmButton: false,
      title: "Como você quer continuar?",
      html: (
        <>
          <p>
            Não será possível <strong>escolher individualmente</strong> o{" "}
            <strong>Destino</strong> para a solicitação.
          </p>
          <p>
            Dessa forma, todas as <strong>solicitações</strong> passarão a ter o
            mesmo Destino quando criadas.
          </p>
          <p>
            Selecione o tipo de solicitação de pagamento que você quer fazer.
          </p>
          <DestinationContainer>
            <button
              type="button"
              className="form-button darkBlue-bkg"
              onClick={dialog.clickDeny}
            >
              Financeiro
            </button>
            <button
              type="button"
              className="form-button yellow-bkg"
              onClick={dialog.clickConfirm}
            >
              Dep. Pessoal
            </button>
            <button
              type="button"
              className="form-button red-bkg"
              onClick={dialog.clickCancel}
            >
              Ops, me enganei. Quero voltar!
            </button>
          </DestinationContainer>
        </>
      ),
    });

    if (result.isDismissed) {
      return null;
    }

    return result.isConfirmed
      ? ESolicitImportDestination.PersonnelDepartment
      : ESolicitImportDestination.Financial;
  }, [state.availableDestinations, dialog]);

  const defineRequestsStatus = useCallback(async () => {
    const result = await dialog.fire({
      icon: "question",
      allowEnterKey: false,
      showConfirmButton: false,
      title: "Você deseja solicitar aprovação?",
      html: (
        <>
          <p>
            Não será possível <strong>escolher individualmente</strong> se a
            solicitação será <strong>aprovada agora</strong> ou{" "}
            <strong>depois</strong>.
          </p>
          <p>
            Dessa forma, todas as <strong>solicitações</strong> passarão a ter o
            mesmo status quando criadas.
          </p>
          <StatusContainer>
            <button
              type="button"
              className="form-button orange-bkg"
              onClick={dialog.clickDeny}
            >
              Solicitar aprovação depois
            </button>
            <button
              type="button"
              className="form-button green-bkg"
              onClick={dialog.clickConfirm}
            >
              Solicitar aprovação agora
            </button>
          </StatusContainer>
        </>
      ),
    });

    if (result.isDismissed) {
      return null;
    }

    return result.isConfirmed
      ? ESolicitImportStatus.Requested
      : ESolicitImportStatus.NotRequested;
  }, [dialog]);

  const warnIfHasNoNfsAttachments = useCallback(
    async (imports: ISolicitImportEntity[]) => {
      const rowsWithNoNfsAttached = imports
        .filter(formRow => {
          return formRow.storageFiles.every(
            a =>
              a.type !== EAttachmentType.NFe && a.type !== EAttachmentType.NFSe,
          );
        })
        .map(({ company, provider, documentNumber }) => ({
          documentNumber,
          companyName: company?.label || "",
          providerName: provider?.label?.replace(/ - .+/g, "") || "",
        }));

      if (rowsWithNoNfsAttached.length !== 0) {
        await dialog.fire({
          icon: "warning",
          title: "Atenção",
          html: (
            <>
              Não consta nos anexos dessas solicitações uma nota fiscal ou
              documento equivalente. Você deverá editar as solicitações e anexar
              uma nota assim que recebê-la.
              <ErrorsContainer>
                {rowsWithNoNfsAttached.map(
                  ({ documentNumber, companyName, providerName }, index) => {
                    const key = `${documentNumber}-${index}`;
                    return (
                      <div key={key}>
                        {documentNumber} - {companyName} - {providerName}
                      </div>
                    );
                  },
                )}
              </ErrorsContainer>
            </>
          ),
        });
      }
    },
    [dialog],
  );

  const handleFinalStep = useCallback(
    async (
      importResponse: ISolicitImportEntity[],
      destination: ESolicitImportDestination,
    ) => {
      const hasErrors = importResponse.some(
        d => d.hasError || d.hasAssessmentError,
      );

      const destinationLang = solicitImportDestinationLang[destination];

      if (!hasErrors) {
        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Solicitações para o ${destinationLang} realizadas com sucesso.`,
        });
        navigate(`${SoulRoutes.PAYMENT_REQUESTS.path}`);
        return;
      }

      reset({ imports: importResponse });

      const hasAccountErrors = importResponse.some(d => d.hasError);

      if (hasAccountErrors) {
        await dialog.fire({
          icon: "error",
          title: "Opa!",
          text: "Não é possível continuar. Um ou mais lançamentos informados na segunda etapa se tornaram inconsistentes.",
        });

        handleValidationErrors();

        setState(old => ({
          ...old,
          currentStep: ESolicitImportSteps.VALIDATION,
        }));
        return;
      }

      const hasAssessmentErrors = importResponse.some(
        d => d.hasAssessmentError,
      );

      if (hasAssessmentErrors) {
        handleFinalStepErrors();

        const mappedErrors = importResponse.flatMap(data => {
          return data.assessments.flatMap(assessment => {
            return Object.values(assessment.fieldErrors);
          });
        });

        const uniqueErrors = new Set(mappedErrors);
        const errorsList = Array.from(uniqueErrors.values());

        await dialog.fire({
          icon: "error",
          title: "Opa!",

          html: (
            <div className="import-file-error-dialog">
              <p className="import-file-error-message">
                Essa planilha <strong>não pôde</strong> ser importada. Corrija
                os problemas e tente novamente.
              </p>
              <div className="import-file-error-container">
                {errorsList.map((error, index) => {
                  const errorKey = `error#${index}`;
                  return (
                    <div key={errorKey} className="import-file-error-warning">
                      <FaTimesCircle />
                      <p>{error}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          ),
        });
      }
    },
    [dialog, handleFinalStepErrors, handleValidationErrors, navigate, reset],
  );

  const handleImportData = useCallback(
    async ({ imports }: ISolicitImportForm) => {
      const cancelImportation = await checkForAttachments(imports);

      if (cancelImportation) {
        dialog.close();
        return;
      }

      const destination = await defineRequestsDestinations();

      if (destination === null) {
        dialog.close();
        return;
      }

      const status = await defineRequestsStatus();

      if (status === null) {
        dialog.close();
        return;
      }

      await warnIfHasNoNfsAttachments(imports);

      dialog.fire({
        title: "Aguarde",
        text: "Validando dados da importação",
        allowEscapeKey: false,
        allowOutsideClick: false,
      });

      dialog.showLoading();

      const updatedImports = handleBarcodeUpdate(imports);

      try {
        ReactTooltip.rebuild();
        const response = await saveImportData({
          status,
          destination,
          importData: updatedImports,
        });
        await handleFinalStep(response, destination);
      } finally {
        dialog.close();
      }
    },
    [
      dialog,
      saveImportData,
      handleFinalStep,
      handleBarcodeUpdate,
      checkForAttachments,
      defineRequestsStatus,
      warnIfHasNoNfsAttachments,
      defineRequestsDestinations,
    ],
  );

  const handleNextStep = useCallback(() => {
    const stepsActions = {
      [ESolicitImportSteps.FILE]: handleSubmitFile,
      [ESolicitImportSteps.FINAL]: handleSubmit(handleImportData),
      [ESolicitImportSteps.VALIDATION]: handleSubmit(handleFormValidation),
    };

    stepsActions[state.currentStep]();
  }, [
    handleSubmit,
    handleSubmitFile,
    handleImportData,
    state.currentStep,
    handleFormValidation,
  ]);

  const showLeavingProcessDialog = useCallback(() => {
    return dialog.fire({
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      title: "Você está certo disso?",
      html: "Você está no meio do processo de importações.<br />Se sair agora, todo o seu progresso será perdido!",
    });
  }, [dialog]);

  const handleReturnStep = useCallback(async () => {
    const formList = getValues("imports");

    if (
      formList.length !== 0 &&
      state.currentStep === ESolicitImportSteps.VALIDATION
    ) {
      const result = await showLeavingProcessDialog();
      if (result.dismiss) {
        return;
      }
    }

    if (state.currentStep - 1 < 0) {
      navigate(-1);
      return;
    }

    const newStep = state.currentStep - 1;

    if (newStep === ESolicitImportSteps.FILE) {
      setState({
        files: [],
        expandedRows: {},
        availableDestinations: [],
        showInvalidOnly: false,
        isUploadingFile: false,
        paymentMethodsEnums: [],
        documentStatusEnums: [],
        isDownloadingTemplate: false,
        formsValidatingDocumentNumber: [],
        currentStep: ESolicitImportSteps.FILE,
      });

      reset({ imports: [] });
    }

    if (newStep === ESolicitImportSteps.VALIDATION) {
      formList.map((_, index) => {
        resetField(`imports.${index}.hasAssessmentError`);
        clearErrors(`imports.${index}.hasAssessmentError`);
        resetField(`imports.${index}.valueAssessmentLeft`);
        clearErrors(`imports.${index}.valueAssessmentLeft`);
        return null;
      });

      setState(old => ({
        ...old,
        expandedRows: {},
        currentStep: newStep,
        showInvalidOnly: false,
      }));
    }
  }, [
    reset,
    navigate,
    getValues,
    resetField,
    clearErrors,
    state.currentStep,
    showLeavingProcessDialog,
  ]);

  const handleNavLinkClick = useCallback(
    async (destinyRoute: string, event: Event) => {
      const formList = getValues("imports");

      if (
        formList.length === 0 ||
        state.currentStep === ESolicitImportSteps.FILE
      ) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();

      const result = await showLeavingProcessDialog();

      if (result.dismiss) {
        return;
      }

      navigate(destinyRoute || SoulRoutes.HOME.path);
    },
    [getValues, navigate, showLeavingProcessDialog, state.currentStep],
  );

  const handleRemoveForm = useCallback(
    async (
      formIds: string[],
      removeCallback: (formsIds: string[]) => boolean,
    ) => {
      const isSingleForm = formIds.length === 1;
      const shouldRemove = await showRemoveFormsDialog(isSingleForm);
      if (shouldRemove) {
        const shouldReset = removeCallback(formIds);
        handleAfterRemove(shouldReset, formIds.length);
      }
    },
    [handleAfterRemove, showRemoveFormsDialog],
  );

  useEffect(() => {
    if (!isRequesterOrManager) {
      navigate(`${SoulRoutes.PAYMENT_REQUESTS.path}`, { replace: true });
    }
  }, [isRequesterOrManager, navigate]);

  const value = useMemo(
    () => ({
      form,
      state,
      useProvider,
      getFormIndex,
      useDebtImport,
      handleNextStep,
      handleRowToggle,
      handleReturnStep,
      handleRemoveForm,
      handleFileUpdate,
      handleGetTemplate,
      handleAfterRemove,
      handleNavLinkClick,
      handleInputClassName,
      showRemoveFormsDialog,
      handleExpandAllChange,
      validateDocumentNumber,
      handleInvalidOnlyChange,
    }),
    [
      form,
      state,
      useProvider,
      getFormIndex,
      useDebtImport,
      handleNextStep,
      handleRowToggle,
      handleReturnStep,
      handleFileUpdate,
      handleRemoveForm,
      handleGetTemplate,
      handleAfterRemove,
      handleNavLinkClick,
      handleInputClassName,
      handleExpandAllChange,
      showRemoveFormsDialog,
      validateDocumentNumber,
      handleInvalidOnlyChange,
    ],
  );

  return (
    <SolicitImportPageContext.Provider value={value}>
      {children}
    </SolicitImportPageContext.Provider>
  );
}

export function useSolicitImportPage() {
  const context = useContext(SolicitImportPageContext);
  return context;
}

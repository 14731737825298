import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdateProviderBankDataContract } from "../../domain/contracts/updateProviderBankDataContract";
import {
  ProviderBankForm,
  ProviderForm,
} from "../../domain/entities/providerForm";

type ProviderBankDataPayload = {
  [K in keyof ProviderBankForm]-?: ProviderBankForm[K] | "";
} & { providerId: string };

export class UpdateProviderBankDataService
  implements IUpdateProviderBankDataContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  updateProviderBankData(data: ProviderForm) {
    const url = "/Providers/BankData";
    const userEntity = this.getUserLocalService.get();

    const payload: ProviderBankDataPayload = {
      providerId: data.id,
      bank: data?.bank || "",
      bankAccount: data?.bankAccount || "",
      bankBranch: data?.bankBranch || "",
      documentHolder: data?.documentHolder || "",
      bankBranchDigit: data?.bankBranchDigit || "",
      bankAccountDigit: data?.bankAccountDigit || "",
      bankAccountType: data?.bankAccountType || "",
      holderName: data?.holderName || "",
      type: data.type,
    };

    return this.api.put(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}

import { useEffect, useState } from "react";
import {
  DashboardChartsType,
  EDashboardChartType,
  IDashboardDataEntity,
} from "../../../domain/entities/dashboardDataEntity";
import { IDashboardToolbarFiltersEntity } from "../../../domain/entities/dashboardToolbarFiltersEntity";
import { MakeDashboard } from "../../../main/makeDashboard";
import { EDashboardCardType } from "../../components/DashboardCard";
import { DASHBOARD_DEFAULT_FILTER_VALUES } from "../../components/DashboardToolbar";

interface IDashboardPageState {
  loading: boolean;
  filters: IDashboardToolbarFiltersEntity;
  data: IDashboardDataEntity;
}

interface IUseDashboardPageParams {
  useDashboard: MakeDashboard;
}

export function useDashboardPage({
  useDashboard: { getDashboardData },
}: IUseDashboardPageParams) {
  const [state, setState] = useState<IDashboardPageState>(() => {
    const keys = Object.values(EDashboardChartType);

    const charts = keys.reduce<DashboardChartsType>(
      (acc: DashboardChartsType, cur) => {
        acc[cur] = {
          datasets: [],
          labels: [],
        };

        return acc;
      },
      {} as DashboardChartsType,
    );

    return {
      loading: true,
      data: {
        balance: { value: 0 },
        revenue: { value: 0 },
        expenses: { value: 0 },
        charts,
      },
      filters: DASHBOARD_DEFAULT_FILTER_VALUES,
    };
  });

  let balanceCardType: EDashboardCardType | undefined;

  if (state.data.balance.value > 0) {
    balanceCardType = EDashboardCardType.Profit;
  }

  if (state.data.balance.value < 0) {
    balanceCardType = EDashboardCardType.Loss;
  }

  const fetchData = async (params: IDashboardToolbarFiltersEntity) => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const data = await getDashboardData(params);

      setState(prevState => ({
        ...prevState,
        loading: false,
        data: { ...data },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleFiltersChange = (value: IDashboardToolbarFiltersEntity) => {
    setState(prevState => ({
      ...prevState,
      loading: true,
      filters: { ...value },
    }));

    fetchData(value);
  };

  useEffect(() => {
    function init() {
      fetchData(state.filters);
    }

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    balanceCardType,
    handleFiltersChange,
    state,
  };
}

import { useMemo } from "react";
import { useHomePageLinks } from "../../hooks/useHomePageLinks";
import { LinkCard, LinkCardProps } from "../LinkCard";
import { Container } from "./styles";

export function HomePage() {
  const appVersion = process.env.REACT_APP_VERSION;

  const pagesToDisplay: LinkCardProps[] = useHomePageLinks();

  const year = useMemo(() => {
    const date = new Date();
    return date.getFullYear();
  }, []);

  return (
    <Container>
      <div className="cards-container">
        {pagesToDisplay.map(page => {
          return <LinkCard key={page.title} {...page} />;
        })}
      </div>

      <footer>
        <p>
          © {year}{" "}
          <a href="https://pecege.com" target="_blank" rel="noreferrer">
            Pecege
          </a>
        </p>
        <p>v{appVersion}</p>
      </footer>
    </Container>
  );
}

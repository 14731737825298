import { ChangeEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { IconTooltip } from "../../../../core/presentation/components/IconTooltip";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";
import { departmentDestinationProfiles } from "../../../../user/domain/entities/departmentDestinationProfiles";
import { ProviderType } from "../../../domain/entities/providerEntity";
import { ProviderForm } from "../../../domain/entities/providerForm";
import { ProviderFormModalState } from "../ProviderFormModal";
import { ProfessorLabel } from "./styles";

interface SharedFormProps {
  defaultCountry: ProviderFormModalState["defaultCountry"];
  providerTypes: ProviderFormModalState["enums"]["providerTypes"];
  professorTypes: ProviderFormModalState["enums"]["professorTypes"];
  teacherRegimes: ProviderFormModalState["enums"]["teacherRegimes"];
}

export function SharedForm(props: SharedFormProps) {
  const { providerTypes, professorTypes, teacherRegimes, defaultCountry } =
    props;

  const { user } = useUserLocal();
  const allowedProfiles = useAllowedProfiles();
  const { watch, resetField, unregister } = useFormContext<ProviderForm>();

  const type = watch("type");

  const isTypeSelected = typeof type === "number";
  const isIndividualPerson = type === ProviderType.individualPerson;
  const userHasDestinationDepartment = departmentDestinationProfiles.includes(
    user.profile,
  );

  const canSetRetainFee = allowedProfiles(
    EUserProfile.internal,
    EUserProfile.financial,
    EUserProfile.supervisor,
    EUserProfile.financialAccounting,
    EUserProfile.financialManagement,
  );

  const canSetProfessor = allowedProfiles(
    EUserProfile.financial,
    EUserProfile.financialAccounting,
    EUserProfile.financialManagement,
    EUserProfile.supervisor,
  );

  const showRetainFee = isTypeSelected && canSetRetainFee;
  const showProfessorField = isTypeSelected && canSetProfessor;
  const showTeacherRegime = isIndividualPerson && !userHasDestinationDepartment;

  const handleTypeChange = (newType: ProviderType) => {
    if (newType !== ProviderType.individualPerson) {
      unregister("teacherRegime");
    }

    const countryReset =
      newType !== ProviderType.foreign ? defaultCountry : null;

    resetField("name", { defaultValue: "" });
    resetField("email", { defaultValue: "" });
    resetField("document", { defaultValue: "" });
    resetField("phoneNumber", { defaultValue: "" });
    resetField("country", { defaultValue: countryReset });
  };

  return (
    <div className="form-section">
      <div className="form-row">
        <label className="col-6 form-control">
          <span>Tipo</span>
          <Controller
            name="type"
            defaultValue=""
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
                const chosenValue = Number(event.target.value);

                handleTypeChange(chosenValue);

                field.onChange(chosenValue);
              };

              return (
                <>
                  <select
                    {...field}
                    onChange={onChange}
                    className={fieldState?.error ? "isInvalid" : ""}
                  >
                    <option value="" hidden disabled>
                      Tipo de fornecedor
                    </option>
                    {providerTypes.map(typeEnum => {
                      return (
                        <option key={typeEnum.key} value={typeEnum.key}>
                          {typeEnum.value}
                        </option>
                      );
                    })}
                  </select>
                  <InvalidFeedback
                    condition={!!fieldState?.error}
                    message="Este campo é obrigatório"
                  />
                </>
              );
            }}
          />
        </label>
        {showRetainFee ? (
          <label className="col-6 form-control">
            <span>Retém Impostos?</span>
            <Controller
              name="retainFee"
              defaultValue={false}
              render={({ field }) => {
                const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
                  field.onChange(event.target.value === "Sim");
                };

                return (
                  <select
                    {...field}
                    onChange={onChange}
                    data-testid="sel-retain-fee"
                    value={field.value ? "Sim" : "Não"}
                  >
                    <option value="Sim">Sim</option>
                    <option value="Não">Não</option>
                  </select>
                );
              }}
            />
          </label>
        ) : null}
      </div>
      <div className="form-row">
        {showProfessorField ? (
          <label className="col-6 form-control">
            <ProfessorLabel>
              <p>Professor</p>
              <small>(opcional)</small>
              <IconTooltip
                icon="pi pi-question-circle"
                position="right"
                title="Professor"
                text="Esse campo é destinado somente para professores.
                  Caso ele esteja vazio, o fornecedor não é
                  considerado professor."
              />
            </ProfessorLabel>
            <Controller
              name="professorType"
              defaultValue=""
              render={({ field }) => {
                const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
                  const chosenValue = event.target.value;

                  if (chosenValue === "") {
                    field.onChange("");
                    return;
                  }

                  field.onChange(Number(chosenValue));
                };

                return (
                  <select
                    {...field}
                    id="sel-professor"
                    onChange={onChange}
                    data-testid="sel-professor"
                  >
                    <option value="">Selecione uma opção</option>
                    {professorTypes.map(professorEnum => {
                      return (
                        <option
                          key={professorEnum.key}
                          value={professorEnum.key}
                        >
                          {professorEnum.value}
                        </option>
                      );
                    })}
                  </select>
                );
              }}
            />
          </label>
        ) : null}
        {showTeacherRegime ? (
          <label className="col-6 form-control">
            <span>
              Regime do Docente <small>(opcional)</small>
            </span>
            <Controller
              name="teacherRegime"
              defaultValue=""
              render={({ field }) => {
                const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
                  const chosenValue = event.target.value;

                  if (chosenValue === "") {
                    field.onChange("");
                    return;
                  }

                  field.onChange(Number(chosenValue));
                };

                return (
                  <select {...field} onChange={onChange}>
                    <option value="">Selecione uma opção</option>
                    {teacherRegimes.map(regimeEnum => (
                      <option key={regimeEnum.key} value={regimeEnum.key}>
                        {regimeEnum.value}
                      </option>
                    ))}
                  </select>
                );
              }}
            />
          </label>
        ) : null}
      </div>
    </div>
  );
}

import {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { FaCopy, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { useParams } from "react-router-dom";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IEnum } from "../../../../../../core/domain/entities/enum";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { IconTooltip } from "../../../../../../core/presentation/components/IconTooltip";
import { InputBalance } from "../../../../../../core/presentation/components/InputBalance";
import { InputDate } from "../../../../../../core/presentation/components/InputDate";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  NOT_FOUND_OPTION_ID,
  SoulTypeahead,
} from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDateValidator } from "../../../../../../core/presentation/hooks/useDateValidator";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";
import { MakeProvider } from "../../../../../../provider/main/makeProvider";
import { ClassificationAccountLinkFormModal } from "../../../../../../provider/presentation/components/ClassificationAccountLinkFormModal";
import { ProviderFormModal } from "../../../../../../provider/presentation/components/ProviderFormModal";
import {
  IProviderEntity,
  ProviderDocumentType,
} from "../../../domain/entities/providerEntity";
import { MakePaymentRequestForm } from "../../../main/makePaymentRequestForm";
import { ContainerSection } from "./styles";
import { EPaymentRequestPaymentMethod } from "../../../domain/entities/paymentRequestEnums";
import { IPaymentRequestFormEntity } from "../../../domain/entities/paymentRequestFormEntity";
import { EAttachmentType } from "../../../../../../core/domain/entities/attachmentsGridTypes";
import { useDocumentFormatter } from "../../../../../../core/presentation/hooks/useDocumentFormatter";

interface IValidateDocumentNumberParams {
  documentNumberValue: string;
  providerId?: string | undefined;
  paymentRequestId?: string | undefined;
}

interface IFormSectionAccountInfoState {
  providerModalOpen: boolean;
  barcodeFieldDisabled: boolean;
  classificationModalOpen: boolean;
  newlyCreatedProvider: IProviderEntity | undefined;
  documentNumber: {
    loading: boolean;
  };
  provider: {
    options: ITypeaheadOption[] | undefined;
    loading: boolean;
  };
}

interface IFormSectionAccountInfoProps {
  readonly?: boolean;
  useProvider: MakeProvider;
  usePaymentRequestForm: MakePaymentRequestForm;
  options: {
    paymentMethods: IEnum[];
    documentStatuses: IEnum[];
  };
}

export function FormSectionAccountInfo(props: IFormSectionAccountInfoProps) {
  const {
    useProvider,
    readonly = false,
    usePaymentRequestForm,
    options: { documentStatuses, paymentMethods },
  } = props;

  const [state, setState] = useState<IFormSectionAccountInfoState>({
    providerModalOpen: false,
    barcodeFieldDisabled: true,
    classificationModalOpen: false,
    newlyCreatedProvider: undefined,
    documentNumber: {
      loading: false,
    },
    provider: {
      options: [],
      loading: false,
    },
  });

  const invalidDate = useDateValidator();
  const debounceTime = useDebounceTimeAsync();
  const documentFormatter = useDocumentFormatter();
  const form = useFormContext<IPaymentRequestFormEntity>();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const { listLinkedClassificationsAccount } = useProvider;
  const { paymentRequestId } = useParams<"paymentRequestId">();

  const {
    watch,
    control,
    trigger,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = form;

  const {
    searchProvider,
    getProviderById,
    checkDocumentNumber,
    checkDateForPayment,
    providerHasBankingData,
    providerHasClassificationAccount,
  } = usePaymentRequestForm;

  const provider = watch("provider");
  const attachments = watch("storageFiles");
  const paymentMethod = watch("paymentMethod");

  const companyGroupId = currentCompanyGroup.id;
  const providerId = provider?.rawValue as string | undefined;

  const validateDocumentNumber = useCallback(
    async (validateParams: IValidateDocumentNumberParams) => {
      const { documentNumberValue } = validateParams;

      if (!providerId || !documentNumberValue) {
        return true;
      }

      setState(prevState => ({
        ...prevState,
        documentNumber: {
          ...prevState.documentNumber,
          loading: true,
        },
      }));

      let success = true;

      try {
        await checkDocumentNumber({
          documentNumber: documentNumberValue,
          providerId,
          paymentRequestId,
        });
      } catch {
        success = false;
      } finally {
        setState(prevState => ({
          ...prevState,
          documentNumber: {
            ...prevState.documentNumber,
            loading: false,
          },
        }));
      }

      return success;
    },
    [paymentRequestId, checkDocumentNumber, providerId],
  );

  const validateDocumentNumberAsync = async (documentNumberValue: string) => {
    if (readonly) {
      return true;
    }

    await debounceTime(750);

    return validateDocumentNumber({
      documentNumberValue,
      providerId,
      paymentRequestId,
    });
  };

  const openClassificationAccountLinkModal = (
    newlyCreatedProvider: IProviderEntity,
  ) => {
    setState(prevState => ({
      ...prevState,
      classificationModalOpen: true,
      newlyCreatedProvider,
    }));
  };

  const openProviderModal = () => {
    setState(prevState => ({
      ...prevState,
      providerModalOpen: true,
    }));
  };

  const handleProviderCreated = async (newProvider: IProviderEntity) => {
    const clsAccountList = await listLinkedClassificationsAccount(
      companyGroupId,
      newProvider.id,
    );

    const { id, documentType, document, name } = newProvider;

    const isOtherDocType = documentType === ProviderDocumentType.other;

    const docValue = isOtherDocType ? document : documentFormatter(document);

    const label = `${name} ${document ? `- ${docValue}` : ""}`.trim();

    setValue(
      "provider",
      {
        label,
        rawValue: id,
        metadata: newProvider,
      },
      { shouldValidate: true },
    );

    if (Array.isArray(clsAccountList.data) && clsAccountList.data.length) {
      return;
    }

    openClassificationAccountLinkModal(newProvider);
  };

  const handleCloseClassificationModal = (hasLinked = false) => {
    setState(prevState => ({
      ...prevState,
      classificationModalOpen: false,
      newlyCreatedProvider: undefined,
    }));

    if (!hasLinked) {
      setValue("provider", null, { shouldValidate: true });
      setValue("classificationAccount", null, { shouldValidate: true });
    }
  };

  const handleProviderModalRequestClose = () => {
    setState(prevState => ({
      ...prevState,
      providerModalOpen: false,
    }));
  };

  const handleNotFoundOptionSelected = () => {
    openProviderModal();
  };

  const handleSearchProviderChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      provider: {
        ...prevState.provider,
        loading: true,
      },
    }));

    try {
      const response = await searchProvider(companyGroupId, search, 100, true);

      const companies = response.data;

      setState(prevState => ({
        ...prevState,
        provider: {
          ...prevState.provider,
          options: companies,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        provider: {
          ...prevState.provider,
          loading: false,
        },
      }));
    }
  };

  const handleProviderChange = async (event: ISoulTypeaheadChangeEvent) => {
    const selectedProvider = event?.target?.value;

    trigger("documentNumber");

    if (!selectedProvider) {
      return;
    }

    const selectedProviderId = selectedProvider.rawValue as string;

    setState(prevState => ({
      ...prevState,
      provider: {
        ...prevState.provider,
        loading: true,
      },
    }));

    try {
      const providerHasClsAccount = await providerHasClassificationAccount(
        companyGroupId,
        selectedProviderId,
      );

      if (providerHasClsAccount) {
        return;
      }

      const providerEntity = await getProviderById(selectedProviderId);

      openClassificationAccountLinkModal(providerEntity);
    } finally {
      setState(prevState => ({
        ...prevState,
        provider: {
          ...prevState.provider,
          loading: false,
        },
      }));
    }
  };

  const shouldShowBarcodeField = useMemo(() => {
    if (attachments.length <= 0) {
      return false;
    }

    return attachments.some(
      att => att.type === EAttachmentType.Billet && att.active,
    );
  }, [attachments]);

  const barcodeFieldValue = useMemo(() => {
    if (attachments.length <= 0) {
      return "";
    }

    const attBillet = attachments.find(
      att => att.type === EAttachmentType.Billet && att.active,
    );

    if (!attBillet) {
      return "";
    }

    return attBillet.barcode || "";
  }, [attachments]);

  const barcodeFieldDisabled = useMemo(() => {
    let hasBarcode = true;

    const attBillet = attachments.find(
      att => att.type === EAttachmentType.Billet,
    );

    if (attBillet) {
      hasBarcode = !!attBillet.barcode;
      return hasBarcode && state.barcodeFieldDisabled;
    }

    return false && state.barcodeFieldDisabled;
  }, [attachments, state.barcodeFieldDisabled]);

  const handleEditBarcodeButtonClick = () => {
    setState(prevState => ({
      ...prevState,
      barcodeFieldDisabled: !prevState.barcodeFieldDisabled,
    }));
  };

  const handleCopyBarcodeButtonMouseOut = () => {
    setState(prevState => ({
      ...prevState,
      copyCodeTooltipText: "Copiar código de barras",
    }));

    ReactTooltip.rebuild();
  };

  const copyBarcode = useCallback((barcode: string) => {
    navigator.clipboard.writeText(barcode);

    toast.success("Código de barras copiado", {
      autoClose: 2000,
    });
  }, []);

  const handleCopyBarcodeButtonClick = () => {
    const formValue = getValues();
    const { barcode } = formValue;

    if (!barcode) {
      return;
    }

    copyBarcode(barcode);
  };

  const updateBilletBarcodeValue = (barcode: string) => {
    const updatedAtts = [...attachments];

    const index = updatedAtts.findIndex(
      att => att.type === EAttachmentType.Billet && att.active,
    );

    if (index === -1) {
      return;
    }

    updatedAtts[index].barcode = barcode;

    setValue("storageFiles", updatedAtts);
  };

  const handleBarcodeInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const barcode = event.target.value || "";

    updateBilletBarcodeValue(barcode);

    setState(prevState => ({
      ...prevState,
      barcodeFieldDisabled: true,
    }));
  };

  const validateBankDataRequired = useCallback(
    async (val: ITypeaheadOption | null) => {
      if (paymentMethod === null) {
        return true;
      }

      const selectedProviderId = val?.rawValue;

      if (!selectedProviderId || selectedProviderId === NOT_FOUND_OPTION_ID) {
        return true;
      }

      if (
        paymentMethod.key !==
        EPaymentRequestPaymentMethod.CurrentAccountTransfer
      ) {
        return true;
      }

      setState(prevState => ({
        ...prevState,
        provider: {
          ...prevState.provider,
          loading: true,
        },
      }));

      try {
        const providerHasBankData = await providerHasBankingData(
          selectedProviderId as string,
        );

        if (providerHasBankData) {
          return true;
        }
      } finally {
        setState(prevState => ({
          ...prevState,
          provider: {
            ...prevState.provider,
            loading: false,
          },
        }));
      }

      return false;
    },
    [paymentMethod, providerHasBankingData],
  );

  const validatePayUntilLimit = useCallback(
    async (value: string) => {
      try {
        await checkDateForPayment({
          companyGroupId,
          payUntil: value,
        });

        return true;
      } catch (message) {
        return message as string;
      }
    },
    [checkDateForPayment, companyGroupId],
  );

  const handleEditProvider = () => {
    if (!provider) {
      return;
    }

    setState(prevState => ({ ...prevState, providerModalOpen: true }));
  };

  useEffect(() => {
    if (shouldShowBarcodeField) {
      ReactTooltip.rebuild();
    }

    setValue("barcode", barcodeFieldValue);
  }, [barcodeFieldValue, setValue, shouldShowBarcodeField]);

  return (
    <Card>
      <header>Informações do lançamento</header>
      <ContainerSection>
        <div className="form-row">
          <label className="col-8 form-control">
            <span className="with-tooltip">
              Fornecedor{" "}
              <IconTooltip
                position="right"
                title="Fornecedor"
                icon="pi pi-question-circle"
                text="Você pode pesquisar pela Razão Social, Nome Fantasia ou pelo
                  documento (CPF/CNPJ sem pontuação)."
              />
              {state.provider.loading && (
                <i className="pi pi-spin pi-spinner" />
              )}
            </span>
            <Controller
              name="provider"
              rules={{
                validate: {
                  required: val => !!val?.rawValue,
                  bankDataRequired: validateBankDataRequired,
                },
              }}
              render={({ field, fieldState }) => {
                const onChange = (event: ISoulTypeaheadChangeEvent) => {
                  field.onChange(event);
                  handleProviderChange(event);
                };

                return (
                  <div className="provider-field">
                    <SoulTypeahead
                      serverSide
                      id="txt-provider"
                      value={field.value}
                      disabled={readonly}
                      onChange={onChange}
                      placeholder="Fornecedor"
                      data-testid="txt-provider"
                      options={state.provider.options}
                      loading={state.provider.loading}
                      onSearchChange={handleSearchProviderChange}
                      notFoundOptionLabel="+ Adicionar Fornecedor"
                      onNotFoundOptionSelected={handleNotFoundOptionSelected}
                      className={fieldState?.error ? "isInvalid" : undefined}
                    />
                    <button
                      type="button"
                      data-effect="solid"
                      id="btn-edit-provider"
                      data-tip="Editar Fornecedor"
                      data-testid="btn-edit-provider"
                      className={`btn-edit-provider  ${
                        !provider ? "disabled" : ""
                      }`}
                      onClick={handleEditProvider}
                    >
                      <FaEdit />
                    </button>
                  </div>
                );
              }}
            />
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={(errors?.provider as FieldError)?.type === "required"}
            />
          </label>
          <label className="col-4 form-control">
            <span>
              N° documento{" "}
              {state.documentNumber.loading && (
                <i className="pi pi-spin pi-spinner" />
              )}
            </span>
            <input
              {...register("documentNumber", {
                validate: {
                  required: txtValue => !!txtValue,
                  existingDocument: validateDocumentNumberAsync,
                },
              })}
              id="documentNumber"
              disabled={readonly}
              placeholder="N° documento"
              data-testid="txt-documentNumber"
              className={errors.documentNumber ? "isInvalid" : ""}
            />
            <InvalidFeedback
              condition={errors.documentNumber?.type === "required"}
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={errors.documentNumber?.type === "existingDocument"}
              message="Este nº de documento já existe para esse fornecedor."
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-12 form-control">
            <span>Descrição</span>
            <Controller
              name="description"
              rules={{ required: true, maxLength: 255 }}
              render={({ field }) => {
                const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                  const eventClone = { ...event };
                  const cursorEnd = event.target.selectionEnd;
                  const eventValue = event.target.value?.toUpperCase() || "";

                  eventClone.target.value = eventValue;
                  eventClone.target.setSelectionRange(cursorEnd, cursorEnd);

                  field.onChange(eventClone);
                };
                return (
                  <input
                    {...field}
                    disabled={readonly}
                    onChange={onChange}
                    id="txt-description"
                    placeholder="Descrição"
                    data-testid="txt-description"
                    className={errors?.description ? "isInvalid" : ""}
                  />
                );
              }}
            />
            <InvalidFeedback
              condition={errors.description?.type === "required"}
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={errors.description?.type === "maxLength"}
              message="Este campo tem um limite de 255 caracteres."
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-3 form-control">
            <span>Valor</span>
            <Controller
              name="value"
              rules={{
                min: 0.01,
              }}
              render={({ field }) => {
                return (
                  <InputBalance
                    {...field}
                    resetToZero
                    id="txt-value"
                    placeholder="000,00"
                    disabled={readonly}
                    data-testid="txt-value"
                    className={errors?.value ? "isInvalid" : ""}
                  />
                );
              }}
            />
            <InvalidFeedback
              condition={errors.value?.type === "min"}
              message="Este campo é obrigatório"
            />
          </label>
          <label className="col-3 form-control">
            <span>Dt. emissão</span>
            <InputDate
              {...register("issueDate", {
                validate: {
                  required: dtValue => !!dtValue,
                  validDate: dtValue =>
                    !invalidDate(dtValue || "", "dd/MM/yyyy"),
                },
              })}
              id="issueDate"
              disabled={readonly}
              placeholder="00/00/0000"
              data-testid="txt-issueDate"
              className={errors.issueDate ? "isInvalid" : ""}
            />
            <InvalidFeedback
              condition={errors.issueDate?.type === "required"}
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={errors.issueDate?.type === "validDate"}
              message="Formato de data inválida"
            />
          </label>
          <label className="col-3 form-control">
            <span>Pagar em</span>
            <InputDate
              {...register("payUntil", {
                validate: {
                  required: dtValue => !!dtValue,
                  validDate: dtValue =>
                    !invalidDate(dtValue || "", "dd/MM/yyyy"),
                  notLimited: validatePayUntilLimit,
                },
              })}
              id="payUntil"
              disabled={readonly}
              placeholder="00/00/0000"
              data-testid="txt-payUntil"
              className={errors.payUntil ? "isInvalid" : ""}
            />
            <InvalidFeedback
              condition={errors.payUntil?.type === "required"}
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={errors.payUntil?.type === "validDate"}
              message="Formato de data inválida"
            />
            <InvalidFeedback
              condition={errors.payUntil?.type === "notLimited"}
              message={errors?.payUntil?.message || "Data inválida"}
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-5 form-control">
            <span>Método de pagamento</span>
            <Controller
              control={control}
              name="paymentMethod"
              rules={{ required: true }}
              render={({ field: { onChange, ...rest } }) => {
                const handleChange = (
                  event: ChangeEvent<HTMLSelectElement>,
                ) => {
                  const selVal = event.target.value;

                  const sel = paymentMethods.find(
                    option => option.key === Number(selVal),
                  );

                  onChange(sel);
                };

                const selVal = rest.value?.key || "";

                return (
                  <select
                    {...rest}
                    value={selVal}
                    id="paymentMethod"
                    disabled={readonly}
                    onChange={handleChange}
                    data-testid="txt-paymentMethod"
                    placeholder="Método de pagamento"
                    className={errors.paymentMethod ? "isInvalid" : ""}
                  >
                    <option value="" disabled hidden>
                      Método de pagamento
                    </option>
                    {paymentMethods.map(option => {
                      const { key } = option;
                      const optVal = option.value;
                      return (
                        <option key={key} value={key}>
                          {optVal}
                        </option>
                      );
                    })}
                  </select>
                );
              }}
            />
            <InvalidFeedback
              condition={
                (errors?.paymentMethod as FieldError)?.type === "required"
              }
              message="Este campo é obrigatório"
            />
          </label>
          <label className="col-5 form-control">
            <span>Status da documentação</span>
            <Controller
              control={control}
              name="documentStatus"
              rules={{ required: true }}
              render={({ field: { onChange, ...rest } }) => {
                const handleChange = (
                  event: ChangeEvent<HTMLSelectElement>,
                ) => {
                  const selVal = event.target.value;

                  const sel = documentStatuses.find(
                    option => option.key === Number(selVal),
                  );

                  onChange(sel);
                };

                const selVal = rest.value?.key || "";
                return (
                  <select
                    {...rest}
                    value={selVal}
                    id="documentStatus"
                    disabled={readonly}
                    onChange={handleChange}
                    data-testid="txt-documentStatus"
                    placeholder="Método de pagamento"
                    className={errors.documentStatus ? "isInvalid" : ""}
                  >
                    <option value="" disabled hidden>
                      Status da documentação
                    </option>
                    {documentStatuses.map(option => {
                      const { key } = option;
                      const optVal = option.value;
                      return (
                        <option key={key} value={key}>
                          {optVal}
                        </option>
                      );
                    })}
                  </select>
                );
              }}
            />
            <InvalidFeedback
              condition={
                (errors?.documentStatus as FieldError)?.type === "required"
              }
              message="Este campo é obrigatório"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-12 form-control">
            <span>
              Observação <small>(opcional)</small>
            </span>
            <Controller
              name="observation"
              render={({ field }) => {
                const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                  const eventClone = { ...event };
                  const cursorEnd = event.target.selectionEnd;
                  const eventValue = event.target.value?.toUpperCase() || "";

                  eventClone.target.value = eventValue;
                  eventClone.target.setSelectionRange(cursorEnd, cursorEnd);

                  field.onChange(eventClone);
                };
                return (
                  <input
                    {...field}
                    onChange={onChange}
                    disabled={readonly}
                    id="txt-observation"
                    placeholder="Observação"
                    data-testid="txt-observation"
                  />
                );
              }}
            />
          </label>
        </div>
        {shouldShowBarcodeField && (
          <div className="form-row">
            <label className="col-8 form-control form-control-append">
              <span>Código de barras do boleto</span>
              <div>
                <input
                  {...register("barcode", {
                    validate: {
                      required: val => shouldShowBarcodeField && !!val,
                      minLength: val => (val?.length || 0) >= 44,
                      maxLength: val => (val?.length || 0) <= 48,
                      onlyDigit: val => val?.replace(/\d/g, "") === "",
                    },
                    onBlur: handleBarcodeInputBlur,
                  })}
                  id="barcode"
                  data-testid="txt-barcode"
                  disabled={barcodeFieldDisabled}
                  placeholder="Código de barras do boleto"
                  className={errors?.barcode ? "isInvalid" : undefined}
                />
                <button
                  type="button"
                  data-effect="solid"
                  data-tip="Editar código de barras"
                  onClick={handleEditBarcodeButtonClick}
                >
                  <FaEdit />
                </button>
                <button
                  type="button"
                  data-effect="solid"
                  data-tip="Copiar código de barras"
                  onClick={handleCopyBarcodeButtonClick}
                  onBlur={handleCopyBarcodeButtonMouseOut}
                  onMouseOut={handleCopyBarcodeButtonMouseOut}
                >
                  <FaCopy />
                </button>
              </div>
              <InvalidFeedback
                condition={errors?.barcode?.type === "required"}
                message="Campo obrigatório"
              />
              <InvalidFeedback
                condition={errors?.barcode?.type === "onlyDigit"}
                message="O código de barras deve conter apenas números."
              />
              <InvalidFeedback
                condition={errors?.barcode?.type === "minLength"}
                message="O código de barras deve ser maior ou igual 44 dígitos"
              />
              <InvalidFeedback
                condition={errors?.barcode?.type === "maxLength"}
                message="O código de barras deve ter no máximo 48 dígitos"
              />
            </label>
          </div>
        )}
      </ContainerSection>
      <ProviderFormModal
        useProvider={useProvider}
        currentId={providerId || ""}
        isOpen={state.providerModalOpen}
        onProviderCreated={handleProviderCreated}
        onRequestClose={handleProviderModalRequestClose}
      />
      <ClassificationAccountLinkFormModal
        required
        useProvider={useProvider}
        isOpen={state.classificationModalOpen}
        providerEntity={state.newlyCreatedProvider}
        onRequestClose={handleCloseClassificationModal}
      />
    </Card>
  );
}

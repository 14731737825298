import { format, parse } from "date-fns";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetDashboardDataContract } from "../../domain/contracts/getDashboardDataContract";
import { IDatasetEntity } from "../../domain/entities/chartDataEntity";
import {
  DashboardDataEntity,
  EDashboardChartType,
  IDashboardDataEntity,
} from "../../domain/entities/dashboardDataEntity";
import { IDashboardToolbarFiltersEntity } from "../../domain/entities/dashboardToolbarFiltersEntity";
import {
  CHART_DATA_ID_MAP,
  IDashboardDataModel,
} from "../models/dashboardDataModel";

interface IGetDashboardChartsDataParams {
  MacroBoxId: string | undefined;
  InitialFilterDate: string;
  FinalFilterDate: string;
}

export class GetDashboardDataService implements IGetDashboardDataContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getDashboardData({
    macroCategory,
    startDate,
    endDate,
  }: IDashboardToolbarFiltersEntity): Promise<IDashboardDataEntity> {
    const requestPrams: IGetDashboardChartsDataParams = {
      MacroBoxId: (macroCategory?.rawValue as string) || undefined,
      InitialFilterDate: format(
        parse(startDate, "dd/MM/yyyy", new Date()),
        "yyyy-MM-dd",
      ),
      FinalFilterDate: format(
        parse(endDate, "dd/MM/yyyy", new Date()),
        "yyyy-MM-dd",
      ),
    };

    const params = new URLSearchParams(
      requestPrams as unknown as Record<string, string>,
    );

    if (!requestPrams.MacroBoxId) {
      params.delete("MacroBoxId");
    }

    const userEntity = this.getUserLocalService.get();

    const url = "/Dashboard/ChartsData";

    const response = await this.api.get<IDashboardDataModel>(url, {
      params,
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const data: IDashboardDataEntity = this.parseResponse(response);

    return data;
  }

  parseResponse(chartDataModel: IDashboardDataModel): IDashboardDataEntity {
    const result = new DashboardDataEntity({
      balance: { value: chartDataModel.balance },
      expenses: { value: chartDataModel.expenses },
      revenue: { value: chartDataModel.revenues },
    });

    chartDataModel.chartsData?.forEach(chartData => {
      const { id } = chartData;

      const chartType = CHART_DATA_ID_MAP[id];
      const chartConfig = this.getDatasetConfig(chartType) || [];

      result.charts[chartType] = {
        datasets:
          chartData.datasets?.map((dataset, index) => {
            const datasetConfig = chartConfig[index] || {};

            return {
              data: dataset.data || [],
              label: dataset.label || "",
              ...datasetConfig,
            };
          }) || [],
        labels: chartData.labels?.map(label => label || ""),
      };
    });

    return result;
  }

  getDatasetConfig(
    chartDataType: EDashboardChartType,
  ): Partial<IDatasetEntity>[] {
    const config = this.chartTypeConfigTable[chartDataType];

    if (config) {
      return config;
    }

    return [{}];
  }

  private chartTypeConfigTable: Record<
    EDashboardChartType,
    Partial<IDatasetEntity>[]
  > = {
    [EDashboardChartType.RevenueVersusExpenses]: [
      {
        backgroundColor: "#2dce89",
      },
      {
        backgroundColor: "#f5365c",
      },
    ],
    [EDashboardChartType.ExpenseEvolution]: [
      {
        tension: 0.4,
        borderColor: "#5e72e4",
        backgroundColor: "transparent",
      },
      {
        tension: 0.4,
        borderColor: "#66BB6A",
        backgroundColor: "transparent",
      },
      {
        tension: 0.4,
        borderColor: "#FFA726",
        backgroundColor: "transparent",
      },
      {
        tension: 0.4,
        borderColor: "#f5365c",
        backgroundColor: "transparent",
      },
      {
        tension: 0.4,
        borderColor: "#fb6340",
        backgroundColor: "transparent",
      },
      {
        tension: 0.4,
        borderColor: "#ffb881",
        backgroundColor: "transparent",
      },
      {
        tension: 0.4,
        borderColor: "#5e72e4",
        backgroundColor: "transparent",
        borderDash: [5, 5],
      },
      {
        tension: 0.4,
        borderColor: "#66BB6A",
        backgroundColor: "transparent",
        borderDash: [5, 5],
      },
      {
        tension: 0.4,
        borderColor: "#FFA726",
        backgroundColor: "transparent",
        borderDash: [5, 5],
      },
      {
        tension: 0.4,
        borderColor: "#f5365c",
        backgroundColor: "transparent",
        borderDash: [5, 5],
      },
      {
        tension: 0.4,
        borderColor: "#fb6340",
        backgroundColor: "transparent",
        borderDash: [5, 5],
      },
      {
        tension: 0.4,
        borderColor: "#ffb881",
        backgroundColor: "transparent",
        borderDash: [5, 5],
      },
    ],
    [EDashboardChartType.MacroCategoryExpenses]: [
      {
        backgroundColor: "#f5365c",
      },
    ],
  };
}

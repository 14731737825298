import { DropdownFilterParams } from "primereact/dropdown";
import { useCallback, useEffect, useState } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { DASHBOARD_DEFAULT_FILTER_VALUES } from ".";
import { IRelationshipFilterOption } from "../../../../advTable/domain/entities/advTableColumn";
import { ISearchMacroCategoriesContract } from "../../../../core/domain/contracts/searchMacroCategoriesContract";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { IDashboardToolbarFiltersEntity } from "../../../domain/entities/dashboardToolbarFiltersEntity";
import {
  RANGE_GREATER_THAN_ONE_YEAR_ERROR_MESSAGE,
  useValidateDate,
} from "../../hooks/useValidateDate";

interface IDashboardToolbarState {
  macroCategoryOptions: IRelationshipFilterOption[];
  loading: boolean;
}

interface IUseDashboardToolbarParams {
  searchMacroCategories: ISearchMacroCategoriesContract["searchMacroCategories"];
  onFiltersChange(value: IDashboardToolbarFiltersEntity): void;
}

export function useDashboardToolbar({
  searchMacroCategories,
  onFiltersChange,
}: IUseDashboardToolbarParams) {
  const debounceTime = useDebounceTime();

  const { control, reset, handleSubmit, watch } =
    useForm<IDashboardToolbarFiltersEntity>({
      mode: "all",
      defaultValues: DASHBOARD_DEFAULT_FILTER_VALUES,
    });

  const dialog = useSoulDialog();
  const { validateDate } = useValidateDate();

  const [state, setState] = useState<IDashboardToolbarState>({
    macroCategoryOptions: [],
    loading: true,
  });

  const handleSearchMacrocategory = useCallback(
    async (search = "") => {
      setState(prevState => ({
        ...prevState,
        loading: true,
      }));

      try {
        const response = await searchMacroCategories({
          search,
          payloadOptions: { length: 50 },
        });

        setState(prevState => ({
          ...prevState,
          macroCategoryOptions: response.data,
          loading: false,
        }));
      } finally {
        setState(prevState => ({
          ...prevState,
          loading: false,
        }));
      }
    },
    [searchMacroCategories],
  );

  const handleOnFilter = ({ filter = "" }: DropdownFilterParams) => {
    debounceTime(() => {
      handleSearchMacrocategory(filter);
    }, 700);
  };

  const errorHandler = async (
    dunno: FieldErrors<IDashboardToolbarFiltersEntity>,
  ) => {
    if (
      dunno.startDate?.message === RANGE_GREATER_THAN_ONE_YEAR_ERROR_MESSAGE ||
      dunno.endDate?.message === RANGE_GREATER_THAN_ONE_YEAR_ERROR_MESSAGE
    ) {
      await dialog.fire({
        icon: "error",
        title: "Opa!",
        text: `Não é possível aplicar um filtro por data superior a 1 ano.
        Por favor, altere as datas e tente novamente.`,
      });
    }
  };

  const submitHandler = (formValues: IDashboardToolbarFiltersEntity) => {
    onFiltersChange(formValues);
  };

  useEffect(() => {
    function init() {
      handleSearchMacrocategory("");

      const fmtStartDate = DASHBOARD_DEFAULT_FILTER_VALUES.startDate;
      const fmtEndtDate = DASHBOARD_DEFAULT_FILTER_VALUES.endDate;

      reset({
        startDate: fmtStartDate,
        endDate: fmtEndtDate,
        macroCategory: undefined,
      });
    }

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startDate = watch("startDate");
  const endDate = watch("endDate");

  return {
    control,
    handleSubmit,
    validateDate,
    state,
    handleOnFilter,
    errorHandler,
    submitHandler,
    startDate,
    endDate,
  };
}
